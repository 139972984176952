import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { Cookies } from "react-cookie";
import { withClientState } from "apollo-link-state";
import { setContext } from "apollo-link-context";
import { userService } from "../../services/UserService";

export const cookies = new Cookies();

export const cache = new InMemoryCache();
const BASE_URL = process.env.REACT_APP_URL
console.log('BASE_URL:', BASE_URL)
const httpLink = new HttpLink({
  uri: BASE_URL,
});

export const apolloConfig = {
  link: httpLink,
  uri: httpLink,
  onError: ({ networkError, graphQLErrors }) => {
    console.log("🚨 graphQLErrors", graphQLErrors);
    console.log("🚨 networkError", networkError);
  },
  cache,
};

const apolloState = withClientState({
  cache: cache,
  resolvers: {},
  // set default state, else you'll get errors when trying to access undefined state
  defaults: {
    authStatus: {
      __typename: "authStatus",
      status: "loggedOut",
    },
    user: {},
    counterData: {},
  },
});

const authLink = setContext((_, { headers }) => {
  const token = userService.getTokenFromLocalStorage();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloSecureConfig = {
  link: authLink.concat(httpLink),
  uri: BASE_URL,
  cache,
};

export const CacheHelper = {
  get: (query, client, emptyValue) => {
    try {
      return client.cache.readQuery({
        query: query,
      });
    } catch (error) {
      if (emptyValue) return emptyValue;
      return null;
    }
  },
  getString: (query, client, emptyValue) => {
    try {
      let res = client.cache.readQuery({
        query: query,
      });
      return res[Object.keys(res)[0]];
    } catch (error) {
      if (emptyValue) return emptyValue;
      return null;
    }
  },
  getArray: (resultNameKey, query, client, emptyValue) => {
    try {
      let res = client.cache.readQuery({
        query: query,
      });
      if (Array.isArray(res)) return res;
      if (resultNameKey in res) return res[resultNameKey];
    } catch (error) {
      if (emptyValue) return emptyValue;
      return null;
    }
  },
  resetObject: (key, client) => {
    client.cache.writeData({
      data: {
        key: {},
      },
    });
  },
  resetArray: (key, client) => {
    client.cache.writeData({
      data: {
        [key]: [],
      },
    });
  },
  resetString: (key, client) => {
    client.cache.writeData({
      data: {
        [key]: "",
      },
    });
  },
};
