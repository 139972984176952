import React from 'react';
import { ErrorMessage } from 'formik';

class Input extends React.Component {
  render() {
    const { groupStyle, name, labelClass, label, labelcols, fieldClass } = this.props;
    return (
      <div className={`form-group ${groupStyle}`}>
        <div className="row no-gutters align-items-center">
          <label htmlFor={name} className={`form-label ${labelClass}`}>{label}</label>
          <div className={`col-md-${12 - labelcols}`}>
            <input disabled={this.props.disabled ? this.props.disabled : false} type={this.props.type} name={this.props.name} min={this.props.min} max={this.props.max} value={this.props.value ? this.props.value : this.props.defaultValue} className={`form-control ${fieldClass}`} onChange={(event) => this.props.changeInput(event.target.value)} placeholder={this.props.placeholder ? this.props.placeholder : ''} onClick = {this.props.onClick ? this.props.onClick : null}/>
            <ErrorMessage name={name} component="div" />
          </div>
        </div>
      </div>
    )
  }
}
export default Input;