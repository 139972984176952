import React from "react";
import moment from "moment";
import AnimatedNumber from "react-animated-number";
import "./style.scss";
import { DELIVERY_METHOD_PLANS } from "../../constants/constants";

class QuoteDelivery extends React.Component {
  showModal = (deliveryPlan) => {
    this.props.onShowModal(deliveryPlan);
  };

  onToggle = (event) => {
    const { deliveryPlan } = this.props.quoteData;
    if (deliveryPlan === DELIVERY_METHOD_PLANS.standard) {
      this.props.changedVatStandard(event.target.checked);
    } else {
      this.props.changedVatExpress(event.target.checked);
    }
  };

  render() {
    const {
      image,
      category,
      estimateDelivery,
      vatPrice,
      unitPrice,
      totalPrice,
      deliveryPlan,
      DeliveryCost,
    } = this.props.quoteData;
    const vatPriceUnit = Math.round(vatPrice);
    const unitPriceUnit = unitPrice;
    const totalPriceUnit = Math.round(totalPrice) + DeliveryCost;
    return (
      // <div className="container">
      // <div className="row d-flex justify-content-start justify-content-md-center">
      <div className="quote-delivery mb-5">
        {/* <div className="container"> */}
        <div className="col-11 pt-4">
          <div className="row">
            <div className="col-3 pr-0 d-flex justify-content-end">
              <img src={image} alt={category} />
            </div>
            <span className="category col-9">{category}</span>
          </div>
        </div>
        <div className="col-10 offset-2">
          <p className="text col-12 py-2">Estimated delivery:</p>
          <p className="date col-12 ">
            {moment(estimateDelivery).format("ddd, D MMM")}
          </p>
        </div>
        <div className="col-10 offset-2 text-right py-3">
          <div className="final pb-2 d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 text-left">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="toggle"
                      checked={this.props.vat}
                      onChange={(event) => this.onToggle(event)}
                    />
                    <span className="toggle"></span>
                  </label>
                </div>
                {this.props.vat ? (
                  <div className="col-6 px-0">
                    VAT
                    <span className="price pl-1">
                      £
                      <AnimatedNumber
                        style={{
                          transition: "0.8s ease-out",
                          transitionProperty: "background-color, color",
                        }}
                        stepPrecision={0}
                        value={vatPriceUnit}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="col-6 px-0">
                    VAT
                    <span className="price pl-1">
                      £
                      <AnimatedNumber
                        style={{
                          transition: "0.8s ease-out",
                          transitionProperty: "background-color, color",
                        }}
                        stepPrecision={0}
                        value={0}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="final pb-2">
            Unit price
            <span className="price pl-1">
              £
              <AnimatedNumber
                style={{
                  transition: "0.8s ease-out",
                  transitionProperty: "background-color, color",
                }}
                stepPrecision={0}
                value={unitPriceUnit}
              />
            </span>
          </p>
          <p className="final pb-3">
            Total
            <span className="total-price ml-2">
              £
              <AnimatedNumber
                style={{
                  transition: "0.8s ease-out",
                  transitionProperty: "background-color, color",
                }}
                stepPrecision={0}
                value={totalPriceUnit}
              />
            </span>
          </p>
          <button
            type="button"
            className="btn btn-save col-5 col-md-7"
            onClick={() => this.showModal(deliveryPlan)}
          >
            {this.props.updated ? "Update" : "Save"}
          </button>
        </div>
      </div>
      // </div>
      // </div>
      // </div>
    );
  }
}

export default QuoteDelivery;
