import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { validateCreateQuote } from '../../support/validation';
import Spinner from '../Spinner/Spinner';
import './style.scss';

const QuoteModal = (props) => {
    const [quoteModal, changeSaveButton] = useState({ disableButton: false });

    let onSubmitQuote = (values) => {
        changeSaveButton({ disableButton: true });
        if (props.updated) {
            props.updateQuote(values);
        } else {
            props.saveQuote(values);
        }
    }
    let initialValues = {
        name: props.updated ? props.editQuote.QuoteName : '',
        reference: props.updated ? props.editQuote.ClientReference : ''
    }
    return (
        <React.Fragment>
            <div className="col-12">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className={`modal fade col-12 ${props.showModal ? 'show' : 'hide'}`} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="container-fluid">
                                {props.showModal ? <div className={`modal-backdrop fade ${props.showModal ? 'show' : 'hide'}`} onClick={() => props.hideModal()}></div> : null}
                                <div className="modal-dialog modal-dialog-centered col-12 col-lg-4" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Save Quote</h5>
                                        </div>
                                        <Formik enableReinitialize={true} initialValues={initialValues} validate={validateCreateQuote} onSubmit={(values) => onSubmitQuote(values)}>
                                            <Form>
                                                <div className="modal-body">
                                                    <p className="details">Please enter the details:</p>

                                                    <div className="col-12 px-0 py-3">
                                                        <Field className="form-control px-0" type="name" aria-required="true" name="name" placeholder="Name" />
                                                        <ErrorMessage name="name" component="div" className="error" />
                                                    </div>
                                                    <div className="col-12 px-0 pb-3">
                                                        <Field className="form-control px-0" type="name" aria-required="true" name="reference" placeholder="Reference" />
                                                        <ErrorMessage name="reference" component="div" className="error" />
                                                    </div>

                                                </div>
                                                <div className="modal-footer d-flex justify-content-between">
                                                    {quoteModal.disableButton ? (<Spinner size='small' />) : (
                                                        <React.Fragment>
                                                            <button disabled={quoteModal.disableButton ? true : false} type="submit" className="btn btn-save col-5">{props.updated ? 'Update' : 'Save'}</button>
                                                            <button type="button" className="btn btn-cancel col-5" data-dismiss="modal" onClick={() => props.hideModal()}>Cancel</button>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default QuoteModal;