import React from 'react';
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';
import { debounce } from 'lodash';
import { SelectInput } from '../FormElements/index.js';
import OrderList from './OrderList';
import { FILTER_ORDERS } from '../../constants/constants';
import Spinner from '../Spinner/Spinner';
import Header from '../header/Header';
import './style.scss';
const queryString = require('query-string');

const client = new ApolloClient(apolloSecureConfig);

const GET_ORDERS = gql`
query getOrders($page: Int!,$filter:FilterQuote ) {
    getOrders(page: $page,filter: $filter) {
        content {
            OrderId,
            DeliveryCost,
            OrderTotal,
            ProductName,
            DespatchDateTime,
            OrderStatus
            DeliveryPlan
            OrderName,
            warningText
            }
        totalElements,
        totalPages,
        last,
        first
    }
}
`;
const SEARCH_ORDERS = gql`
query searchOrders($orderName: String!,$page: Int!) {
    searchOrders(orderName: $orderName,page: $page) {
        content {
            OrderId,
            OrderTotal,
            ProductName,
            DespatchDateTime,
            OrderStatus
            DeliveryPlan
            OrderName,
            warningText
            }
        totalElements,
        totalPages,
        last,
        first
    }
}
`;
class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: {},
            page: 0,
            searchOrderName: '',
            activeFilterOrder: false,
            selectedFilterOrder: FILTER_ORDERS[0],
            filterValue: FILTER_ORDERS[0].value,
            hasError: ''
        }
        this.getOrdersSubscription = {};
        this.searchOrdersDebounced = debounce(this.onSearchOrders, 500);
    }

    componentDidMount() {
        const { page,filter } = this.state;
        const parsed = queryString.parse(this.props.location.search);
        let variablesOrder = {
            page
        };
        let errorFilter = '';
        if (parsed.filter && parsed.filter.length) {
            const findedFilter = FILTER_ORDERS.find(filterOrder => filterOrder.name.toLowerCase() === parsed.filter.toLowerCase());
            if (findedFilter) {
                variablesOrder.filter = {
                    OrderStatus: findedFilter.value
                };
                this.setState({
                    filterValue: Number(findedFilter.value),
                    selectedFilterOrder: findedFilter
                });
            } else {
                this.setState({
                    hasError: 'Incorrect filter'
                })
                errorFilter = 'Incorrect filter';
            }
        }
        if (errorFilter.length === 0) {
            if(parsed.search && parsed.search.length) {
                client.query({
                    query: SEARCH_ORDERS,
                    variables: { orderName: parsed.search, page: parsed.page ? Number(parsed.page) - 1 : 0 }
                }).then(response => {
                    const { searchOrders } = response.data;
                    this.setState({
                        orders: searchOrders,
                        searchOrderName: parsed.search,
                        page: parsed.page ? Number(parsed.page) - 1 : 0
                    })
                })
            } else {
                if(parsed.page) {
                    client.query({
                        query: GET_ORDERS,
                        variables: { page: parsed.page ? Number(parsed.page) - 1 : 0, filter },
                        fetchPolicy: 'network-only'
                    }).then(response => {
                        const { getOrders } = response.data;
                        this.setState({
                            orders: getOrders,
                            page: parsed.page ? Number(parsed.page) - 1 : 0
                        })
                    })
                } else {
                    this.getOrdersSubscription = client.watchQuery({
                        query: GET_ORDERS,
                        variables: variablesOrder,
                        fetchPolicy: 'cache-and-network'
                    }).subscribe({
                        next: ({ data }) => {
                            if (data) {
                                const { getOrders } = data;
                                this.setState({
                                    orders: getOrders
                                });
                            }
                        },
                        error: (e) => console.error(e)
                    })
                }
            }
        }
    }
    componentWillUnmount() {
        this.onResetState();
        if (Object.keys(this.getOrdersSubscription).length) {
            this.getOrdersSubscription.unsubscribe();
        }
    }

    onResetState = () => {
        this.setState({
            orders: {},
            searchOrderName: '',
            activeFilterOrder: false,
            selectedFilterOrder: FILTER_ORDERS[0],
            filterValue: FILTER_ORDERS[0].value,
            hasError: ''
        });
    }
    onSearchOrders = () => {
        const { searchOrderName } = this.state;
        this.props.history.push(`?search=${searchOrderName}`)
        client.query({
            query: SEARCH_ORDERS,
            variables: { orderName: searchOrderName, page: 0 }
        }).then(response => {
            const { searchOrders } = response.data;
            this.setState({
                orders: searchOrders,
                page: 0
            })
        })
    }

    onChangeSearchOrderName = (event) => {
        this.setState({
            searchOrderName: event.target.value
        });
        this.searchOrdersDebounced()
    }

    onSearchOrdersClick = () => {
        this.searchOrdersDebounced();
    }

    onChangeFilterOrder = (filterValue) => {
        const findedFilter = FILTER_ORDERS.find(filter => filter.value === Number(filterValue));
        const filter = {
            OrderStatus: Number(filterValue)
        };
        this.props.history.replace('?filter=' + findedFilter.name)
        this.setState({
            activeFilterOrder: true,
            filterValue: Number(filterValue),
            selectedFilterOrder: findedFilter
        });
        client.query({
            query: GET_ORDERS,
            variables: { page: 0, filter }
        }).then(response => {
            const { getOrders } = response.data;
            this.setState({
                orders: getOrders
            })
        })
    }
    onChangeDespatchedSort = (despatchedSort) => {
        client.query({
            query: GET_ORDERS,
            variables: { page: 0, filter: { DespatchDateTime: despatchedSort } }
        }).then(response => {
            const { getOrders } = response.data;
            this.setState({
                orders: getOrders
            })
        })
    }

    onChangePage = (page) => {
        const { filterValue,searchOrderName } = this.state;
        const filter = {
            OrderStatus: Number(filterValue)
        };
        if(searchOrderName && searchOrderName.length) {
            client.query({
                query: SEARCH_ORDERS,
                variables: { orderName: searchOrderName, page }
            }).then(response => {
                const { searchOrders } = response.data;
                this.setState({
                    orders: searchOrders,
                    page
                })
            })
            this.props.history.push(`?page=${page+1}&search=${searchOrderName}`)
        } else {
            client.query({
                query: GET_ORDERS,
                variables: { page, filter }
            }).then(response => {
                const { getOrders } = response.data;
                this.setState({
                    orders: getOrders,
                    page
                })
            })
            this.props.history.push(`?page=${page+1}`)
        }
    }

    render() {
        const { orders, searchOrderName, selectedFilterOrder, hasError } = this.state;
        return (
            <ApolloProvider client={client}>
                <section className="orders-section">
                    <div className="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div className="header-bar sticky-top col-12">
                                <div className="row">
                                    <Header locationPathName={this.props.location.pathname} />
                                    <div className="col-12 submenu">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-12">
                                                <div className="row d-flex justify-content-between py-2 py-md-3">
                                                    <div className="col-12 col-md-5 col-lg-5 d-flex align-items-center justify-content-lg-start my-0">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <SelectInput
                                                                    groupStyle='col-12 col-lg-8 col-xl-6 my-0'
                                                                    fieldClass='select'
                                                                    selectedOption={FILTER_ORDERS[0]}
                                                                    options={FILTER_ORDERS}
                                                                    optionClass=''
                                                                    name=''
                                                                    changeSelect={this.onChangeFilterOrder}
                                                                    active={false}
                                                                    itemValue={selectedFilterOrder ? selectedFilterOrder.value : null}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-5 col-lg-5 col-xl-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-2">
                                                        <div className="search-bar col-11 col-lg-8 d-flex align-items-center justify-content-between">
                                                            <input onChange={(event) => this.onChangeSearchOrderName(event)} value={searchOrderName ? searchOrderName : ''} type="text" name="search" className="col-11" />
                                                            <i onClick={() => this.onSearchOrdersClick()} className="fa fa-search"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {hasError ? (<div className="row mt-2 section-error"><div className="col-lg-12"><p>{hasError}</p></div></div>) : Object.keys(orders).length ? (<OrderList changedDespatchedSort={this.onChangeDespatchedSort} orders={orders} changePage={this.onChangePage} currentPage={this.state.page} />) : (<Spinner size='small' />)}
                        </div>
                    </div>
                </section >
            </ApolloProvider>
        );
    }
}

export default withRouter(Orders);