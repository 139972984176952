import React from 'react';
import ReactMapboxGl, { Layer, Feature,Marker } from 'react-mapbox-gl';
import './style.scss';
import { MAP_BOX_ACCESS_TOKEN } from '../../constants/constants';
const MapBox = (props) => {
    const position = [props.lng,props.lat];
    const Map = ReactMapboxGl({
        accessToken:
          MAP_BOX_ACCESS_TOKEN
      });
    return (
        <Map
        style="mapbox://styles/vrprint/cljfl7254009301pld2c5973p"
        containerStyle={{
            height: '185px',
            width: '295px'
        }}
        center={position}
        >
        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
        </Layer>
                <Marker
        coordinates={position}
        anchor="bottom">
        <img src={require('../../assets/img/orders/location-icon.svg')}/>
        </Marker>
        </Map>
    );
}

export default MapBox;