import { toast } from 'react-toastify';
class NotificationService {

    showDefaultNotification = (notification) => {
        toast(notification);
    }

    showSuccessNotification = (notification) => {
        toast.success(notification, {
            position: toast.POSITION.TOP_CENTER
          });
    }

    showErrorNotification = (notification) => {
        toast.error(notification, {
            position: toast.POSITION.TOP_LEFT
          });
    }

    showWarningNotification = (notification) => {
        toast.warn(notification, {
            position: toast.POSITION.BOTTOM_LEFT
          });
    }

    showInfoNotification = (notification) => {
        toast.info(notification, {
            position: toast.POSITION.BOTTOM_CENTER
          });
    }

    showNotificationWithClass = (notification,className) => {
        toast(notification, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: className
          });
    }
}
export let notificationService = new NotificationService();