import React from "react";
class CheckboxInput extends React.Component {
    onChangeCheckBox = (event) => {
        this.props.changed(event.target.checked);
    }
    render() {
        const { id, label, labelClass, checked, inputClass, groupStyle, name, disabled } = this.props;
        return (
            <div className={`form-group ${groupStyle} ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
                <input
                    name={name}
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={this.onChangeCheckBox}
                    className={inputClass}
                    disabled={disabled ? true : false}
                />
                <label className={labelClass} htmlFor={id}>{label}</label>
            </div>
        );
    }
}
export default CheckboxInput;