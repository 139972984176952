import React, { useState } from 'react';
import gql from "graphql-tag";
import moment from 'moment';
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ApolloClient } from "apollo-client";
import { saveAs } from 'file-saver';
import { ARTWORK_TYPES_TITLE, ARTWORK_STATUSES_TITLE, ARTWORK_STATUSES, REGEX_EXPRESSION_TIME, JIMS_URL } from '../../constants/constants';
import { Input } from '../FormElements/index.js';
import CommentsModal from './CommentsModal';
import DatePicker from "react-datepicker";
import { Document, pdfjs, Page } from 'react-pdf';
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { withRouter } from 'react-router';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const client = new ApolloClient(apolloSecureConfig);

const APPROVE_ARTWORK = gql`
  mutation approveArtwork($input: InputArtwork!){
    approveArtwork(input: $input){
        ArtworkId
        Version
        Quantity
        JobId
        ArtworkType
        ArtworkStatus
        ArtworkComments
        DateCreated
        DateProofCreated
        DateProofImposed
        DateProofApproved
        DateProofSentToPrint
        ApprovalRequiredTime
        Expired
    }
  }
`;

const renderSlider = (props) => {
    const { artworks, activeArtworkIndex } = props;
    const renderCircle = () => {
        return artworks.map((artwork, index) => <div className={`circle ${activeArtworkIndex === index ? 'active' : ''} `} key={index} onClick={() => props.changeActiveArtworkIndex(index)}></div>)
    }

    const onChangeForward = () => {
        if (activeArtworkIndex < artworks.length - 1) {
            props.changeActiveArtworkIndex(activeArtworkIndex + 1);
        }
    }

    const onChangeBackward = () => {
        if (activeArtworkIndex > 0) {
            props.changeActiveArtworkIndex(activeArtworkIndex - 1);
        }
    }

    return (
        <div className="row d-flex justify-content-between align-items-center">
            <button type="button" className="button-edit" onClick={() => onChangeBackward()}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.88436 20.2539C15.2984 20.2539 19.7031 15.861 19.7031 10.4614C19.7031 5.06189 15.2984 0.668993 9.88436 0.668992C4.4703 0.668992 0.0656008 5.06189 0.0656003 10.4614C0.0655998 15.861 4.4703 20.2539 9.88436 20.2539ZM9.88436 5.56522L9.88436 9.4822L14.7937 9.4822L14.7937 11.4407L9.88436 11.4407L9.88436 15.3577L4.97498 10.4614L9.88436 5.56522Z" fill="var(--faded)" />
                </svg>
            </button>
            <div className="slider">
                <div className="row d-flex justify-content-center">
                    {renderCircle()}
                </div>
            </div>
            <button type="button" className="button-edit" onClick={() => onChangeForward()}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                </svg>
            </button>
        </div>
    )
}

const renderArtworkOptions = (props) => {

    // const { artworks, activeArtworkIndex } = props;
    const { ArtworkPath, ArtworkId } = props.artworkData;

    const [fileState, changeFileState] = useState({ activeIndex: 1, numPages: 0 });

    const renderNumber = () => {
        return <div>{fileState.activeIndex}/{fileState.numPages}</div>
    }
    const onDocumentLoadSuccess = ({ numPages }) => {
        changeFileState({ ...fileState, numPages })
    }
    const downloadDocument = (name, source) => {
        var fileName = `${props.artworkData.JobId}-${props.artworkData.ArtworkId}`;
        var oReq = new XMLHttpRequest();
        var URLToPDF = source;
        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function () {
            var file = new Blob([oReq.response], {
                type: 'application/pdf'
            });
            saveAs(file, fileName);
        };

        oReq.send();
    }
    const renderFile = () => {
        return [1].map((paper, index) => {
            return (
                <div className="page col-12 d-flex justify-content-center" key={index}>
                    <Document
                        file={`${JIMS_URL}/Artwork/GetApprovalArtworkPdf/${ArtworkId}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={fileState.activeIndex} width={600} renderTextLayer={false} />
                    </Document>
                </div>
            )
        })
    }

    const onChangePageForward = () => {
        if (fileState.activeIndex < fileState.numPages) {
            changeFileState({ ...fileState, activeIndex: fileState.activeIndex + 1 })
        }
    }

    const onChangePageBackward = () => {
        if (fileState.activeIndex > 1) {
            changeFileState({ ...fileState, activeIndex: fileState.activeIndex - 1 })
        }
    }

    return (
        <React.Fragment>
            <div className="col-12 mt-3 mt-md-0">
                <div className="row">
                    <div className="col-5 offset-1">
                        <div className="row d-flex justify-content-between align-items-center">
                            <button type="button" className="button-edit" onClick={() => onChangePageBackward()}>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.88436 20.2539C15.2984 20.2539 19.7031 15.861 19.7031 10.4614C19.7031 5.06189 15.2984 0.668993 9.88436 0.668992C4.4703 0.668992 0.0656008 5.06189 0.0656003 10.4614C0.0655998 15.861 4.4703 20.2539 9.88436 20.2539ZM9.88436 5.56522L9.88436 9.4822L14.7937 9.4822L14.7937 11.4407L9.88436 11.4407L9.88436 15.3577L4.97498 10.4614L9.88436 5.56522Z" fill="var(--faded)" />
                                </svg>
                            </button>
                            <div className="slider">
                                <div className="row d-flex justify-content-center">
                                    {renderNumber()}
                                </div>
                            </div>
                            <button type="button" className="button-edit" onClick={() => onChangePageForward()}>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row d-flex justify-content-end">
                        <div className="col-3">
                        <button type="button" className="button-edit" onClick={() => downloadDocument(ArtworkPath, `${JIMS_URL}/Artwork/GetApprovalArtworkPdf/${ArtworkId}`)}>
                            <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.1146 15.8507H14.0551V9.1171C14.0551 8.99482 13.955 8.89478 13.8327 8.89478H12.1651C12.0428 8.89478 11.9428 8.99482 11.9428 9.1171V15.8507H9.88881C9.7026 15.8507 9.59976 16.0647 9.71371 16.2092L12.8266 20.147C12.8474 20.1736 12.874 20.1951 12.9043 20.2099C12.9347 20.2247 12.968 20.2323 13.0017 20.2323C13.0355 20.2323 13.0688 20.2247 13.0991 20.2099C13.1294 20.1951 13.156 20.1736 13.1768 20.147L16.2897 16.2092C16.4037 16.0647 16.3008 15.8507 16.1146 15.8507Z" fill="var(--faded)" />
                                <path d="M21.322 6.4132C20.049 3.05613 16.8055 0.668945 13.006 0.668945C9.20662 0.668945 5.96308 3.05335 4.69012 6.41042C2.30818 7.0357 0.548828 9.20612 0.548828 11.7851C0.548828 14.8559 3.03638 17.3431 6.10483 17.3431H7.21936C7.34165 17.3431 7.44171 17.2431 7.44171 17.1208V15.4534C7.44171 15.3311 7.34165 15.231 7.21936 15.231H6.10483C5.16817 15.231 4.28711 14.8587 3.63117 14.1834C2.97801 13.5108 2.63059 12.6049 2.66116 11.6656C2.68618 10.9319 2.93632 10.2427 3.38936 9.66188C3.85352 9.06995 4.5039 8.6392 5.22654 8.44744L6.27993 8.17232L6.66626 7.15519C6.90529 6.52158 7.23882 5.92964 7.6585 5.39329C8.07283 4.86169 8.56362 4.39437 9.1149 4.00656C10.2572 3.20342 11.6025 2.77823 13.006 2.77823C14.4096 2.77823 15.7549 3.20342 16.8972 4.00656C17.4503 4.39562 17.9395 4.8625 18.3536 5.39329C18.7733 5.92964 19.1068 6.52436 19.3458 7.15519L19.7294 8.16954L20.78 8.44744C22.2864 8.85318 23.3398 10.2232 23.3398 11.7851C23.3398 12.7049 22.9813 13.572 22.3309 14.2223C22.0119 14.543 21.6325 14.7974 21.2146 14.9705C20.7967 15.1437 20.3485 15.2322 19.8962 15.231H18.7816C18.6593 15.231 18.5593 15.3311 18.5593 15.4534V17.1208C18.5593 17.2431 18.6593 17.3431 18.7816 17.3431H19.8962C22.9646 17.3431 25.4521 14.8559 25.4521 11.7851C25.4521 9.2089 23.6984 7.04125 21.322 6.4132Z" fill="var(--faded)" />
                            </svg>
                        </button>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 my-3 d-flex justify-content-center">
                {renderFile()}
            </div>
        </React.Fragment>
    )
}

const OrderArtwork = (props) => {
    const { artworks, activeArtworkIndex, job } = props;
    const { ArtworkId, ArtworkType, Version, ApprovalRequiredTime, ArtworkStatus, DateCreated, DateProofApproved, ArtworkComments, Expired,NextWorkingDay } = props.artworkData;

    const [startDate, setStartDate] = useState(null);
    const [showDate, setShowDate] = useState({ open: false, selected: false });
    // Approval Button Disable Logic
    const [submitted, setSubmitted] = useState(Expired ? (showDate.selected ? false : true) : false);

    const [startTime, setStartTime] = useState('5:00pm');
    const [showTime, setShowTime] = useState({ selected: false });

    const artworkUpdate = (updatedArtwork) => {
        props.curentJobUpdate(updatedArtwork)
    }

    const [commentState, changeCommentState] = useState({ comment: '', showModal: false })
    const onChangeComment = (value) => {
        changeCommentState({ comment: value })
    }

    const approveArtwork = () => {
        let bodyArtwork = {
            artworkId: artworks[activeArtworkIndex].ArtworkId,
            comment: commentState.comment
        }
        if (props.approvalRequest) {
            bodyArtwork.clientContactKey = props.clientContactKey;
        }
        if (Expired) {
            const time = startTime.match(/(am|pm|AM|PM)/);
            const fullDate = moment(startDate).format("YYYY-MM-DD") + ' ' + startTime.replace(/(am|pm|AM|PM)/, '') + ' ' + time[0];
            bodyArtwork.dateTime = fullDate;
        }

        client.mutate({
            mutation: APPROVE_ARTWORK,
            variables: {
                input: bodyArtwork
            }
        }).then((response) => {
            artworkUpdate(response.data.approveArtwork)
            changeCommentState({ comment: '' })
        })
    }

    const showCommentsModal = () => {
        changeCommentState({ showModal: true })
    }

    const validate = (value) => {
        return REGEX_EXPRESSION_TIME.time.test(value);
    }
    const nwd = parseInt(NextWorkingDay)
    const renderExpireBlock = () => {
        return (
            <React.Fragment>
                <div className="col-10 offset-2 px-0 col-md-8 offset-md-2 my-3 data">Approval Required by:</div>
                <div className="col-12 px-0 col-md-11 offset-md-1 mt-1 mb-3 time">
                    <div className="row d-flex align-items-center">
                        <div className="col-2 col-md-1 px-0 d-flex justify-content-end justify-content-md-center">
                            <div className={`${Expired && (showDate.selected ? false : true) ? ' circle active expire' : ''}`}></div>
                        </div>
                        <p className={`col-10 px-2 col-md-10 px-md-3 value ${Expired && (showDate.selected ? false : true) ? 'expire' : ''}`}>{ApprovalRequiredTime}</p>
                    </div>
                </div>
                <div className="expired col-9 offset-2 px-0 col-md-8 offset-md-2">
                    <div className="row">
                        <p className={`col-12 ${Expired && (showDate.selected ? false : true) ? 'expire' : 'd-none'}`}>Sorry, but the required proof approval time has elapsed. Please choose another despatch date for your job and click on Submit.</p>
                        <div className="col-12 px-0 mt-4">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 date-picker">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <label htmlFor="date" className="data col-12 px-0">Date:</label>
                                                <DatePicker
                                                    minDate={nwd}
                                                    className="col-12 date-input"
                                                    open={showDate.open}
                                                    onClickOutside={() => setShowDate({ open: false, selected: showDate.selected })}
                                                    selected={startDate ? startDate : ''}
                                                    dateFormat="eee, d MMM"
                                                    onChange={date => { setStartDate(date); setShowDate({ open: false, selected: true }); setSubmitted(false) }}
                                                    onInputClick={() => { setShowDate({ open: true, selected: showDate.selected }) }}
                                                />
                                            </div>
                                            <button className="date-icon float-right" onClick={() => { setShowDate({ open: true }) }}>
                                                <img src={require('../../assets/img//artworks/date-icon.svg')} alt="icon" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={`col-12 ${Expired && (showDate.selected ? false : true) ? 'expire' : 'd-none'}`}>Please note that the Order Date is changed so if there are more than one job in your order, the other jobs will be affected.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <section className="order-artwork col-12 col-lg-10 offset-lg-1 mt-5">
            <div className="row">
                <div className="col-12 col-md-5 mb-3">
                    <div className="col-9 offset-2 px-0 col-md-8 offset-md-2 slider mb-3">
                        <div className="container">
                            {renderSlider(props)}
                        </div>
                    </div>
                    <div className="col-10 offset-2 px-0 col-md-8 offset-md-2 id mt-3">Artwork# {ArtworkId} </div>
                    <div className="col-10 offset-2 px-0 col-md-8 offset-md-2 data">
                        <div className="row">
                            <div className="col-6">
                                <div className="row mt-3">
                                    <p className="col-12">Type:</p>
                                    <p className="col-12 mt-1 value">{ARTWORK_TYPES_TITLE[ArtworkType].title}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row mt-3">
                                    <p className="col-12">Version:</p>
                                    <p className="col-12 mt-1 value">v{Version}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(Expired && ArtworkStatus === ARTWORK_STATUSES.ProofSuccess && job.IsOnAutoProof !== 1) ? renderExpireBlock() : null}

                    {(ArtworkStatus !== ARTWORK_STATUSES.ProofSuccess || job.IsOnAutoProof === 1) ?
                        <React.Fragment>
                            <div className="col-10 offset-2 px-0 col-md-8 offset-md-2">
                                <div className="row mt-3">
                                    <p className="col-12 data">Approval Required by:</p>
                                    <p className="col-12 mt-1 value">{ApprovalRequiredTime}</p>
                                </div>
                            </div>
                            <div className={`artwork col-10 offset-2 px-0 col-md-8 offset-md-2`}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <p className="col-12">Status:</p>
                                            <div className="status mt-1 col-12">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="row">
                                                            <div className={`${ArtworkStatus === ARTWORK_STATUSES.ClientApproved || job.IsOnAutoProof === 1 ? 'circle active' : ''} ${ArtworkStatus === ARTWORK_STATUSES.ClientRejected || (ArtworkStatus === ARTWORK_STATUSES.ProofSuccess) && job.IsOnAutoProof !== 1 ? 'circle active expire' : ''}`}></div>
                                                        </div>
                                                    </div>
                                                    <p className={`value ${ArtworkStatus === ARTWORK_STATUSES.ClientRejected || (ArtworkStatus === ARTWORK_STATUSES.ProofSuccess)  && job.IsOnAutoProof !== 1 ? 'expire' : ''}`}>{job.IsOnAutoProof === 1 ? 'Auto approved' : ARTWORK_STATUSES_TITLE[ArtworkStatus].title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <p className="col-12 data">Date:</p>
                                            <p className="col-12 mt-1 value">{DateProofApproved ? moment(Number(DateProofApproved)).format("ddd, D MMM h:mma") : moment(Number(DateCreated)).format("ddd, D MMM h:mma")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <p className="col-12 data">Comments:</p>
                                            <p className="col-12 mt-1 value">{ArtworkComments}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : null}

                    {(ArtworkStatus === ARTWORK_STATUSES.ProofSuccess && job.IsOnAutoProof !== 1) ?
                        <React.Fragment>
                            <div className={`col-9 offset-2 px-0 col-md-8 offset-md-2 ${!submitted ? Expired ? (showDate.selected ? '' : 'disabled') : '' : 'disabled'}`}>
                                <div className="row mt-md-5">
                                    <Input
                                        disabled={!submitted ? Expired ? (showDate.selected ? false : true) : false : true}
                                        placeholder="Your comments:"
                                        type="text"
                                        name="comment"
                                        labelcols={0}
                                        fieldClass="text-input"
                                        labelClass="col-12"
                                        groupStyle='col-12'
                                        value={commentState.comment}
                                        defaultValue={commentState.comment}
                                        changeInput={(value) => onChangeComment(value)}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row d-flex justify-content-between mt-4">
                                        <button disabled={submitted} type="button" className="btn btn-save col-5" onClick={(updatedArtwork) => {approveArtwork(updatedArtwork); setSubmitted(true)}}>Approve</button>
                                        <button disabled={submitted} type="button" className="btn btn-reject col-5" onClick={() => showCommentsModal()}>Reject</button>
                                    </div>
                                    <CommentsModal approvalRequest={props.approvalRequest} clientContactKey={props.clientContactKey} showModal={commentState.showModal} hideModal={() => changeCommentState({ showModal: false })} artworkUpdate={(updatedArtwork) => artworkUpdate(updatedArtwork)} artworkId={artworks[activeArtworkIndex].ArtworkId} />
                                </div>
                            </div>
                        </React.Fragment>
                        : null}
                </div>

                <div className="col-12 mb-3 col-md-7">
                    {renderArtworkOptions(props)}
                </div>
            </div>
        </section>
    );
}

export default withRouter(OrderArtwork);
