import React, { useEffect } from 'react';
import moment from 'moment';
import BarOrderStatus from '../BarOrderStatus/BarOrderStatus';
import Spinner from '../Spinner/Spinner';
import MapBox from '../Mapbox/Mapbox';
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ORDER_STATUSES_BAR, ORDER_STATUSES_TITLE, DELIVERY_METHOD_PLANS, JOB_STATUSES_TITLE, VIEW_BAR_STATUS } from '../../constants/constants';
import './style.scss';
const client = new ApolloClient(apolloSecureConfig);

const GET_COORDS = gql`
    query getCoords($orderId: Int!) {
        getCoords(orderId: $orderId) {
            longitude
            latitude
       }
     }
`;

const renderJobList = (jobs, props) => {
    return jobs.map((job, index) => {
        return (
            <div className="row mb-3 job" key={index} onClick={() => { props.onChangeActiveTab(); props.changeActiveJobIndex(index) }}>
                <div className="col-2 col-md-2 px-0">{job.JobId}</div>
                <div className="col-6 pr-0 col-md-3">{job.JobName ? job.JobName : ''}</div>
                <div className="col-2 d-none d-md-inline-block">{job.Quantity}</div>
                <div className="col-2 col-md-4">
                    <div className="row">
                        <div className="col-5 col-md-2 px-0 d-flex justify-content-end">
                            <div className={`circle active ${job.warningText ? 'expire' : ''}`}></div>
                        </div>
                        <div className={`col-10 pr-md-0 d-none d-md-inline-block ${job.warningText ? 'expire' : ''}`}>{job.warningText ? job.warningText : JOB_STATUSES_TITLE[job.JobStatus].title}</div>
                    </div>
                </div>
                <div className="col-1 d-flex justify-content-md-end align-items-baseline">
                    <button type="button" className={`button-edit ${job.warningText ? 'expire' : ''}`}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                        </svg>
                    </button>
                </div>
            </div>
        )
    })
}




const OrderDetail = (props) => {
    const { OrderId, OrderName, POReference, ClientReference, DateCreated, deliveryMethod, OrderTotal, DeliveryCost, jobs, OrderStatus, warningText, estimateDelivery } = props.orderData;

    useEffect(() => {
        props.resetCurrentJob();
    }, [])

    return (
        <ApolloProvider client={client}>
        <section className="order-detail col-12 col-xl-10 offset-xl-1 mt-5">
            <div className="row">
                <div className="col-12 col-md-5">
                    <div className="col-12 id">
                        <div className="row">
                            <div className="col-1 col-md-2 px-0 d-flex justify-content-center align-items-center justify-content-lg-end">
                                {warningText ? <div className="circle active expire"></div> : null}
                            </div>
                            <span className="col-11 col-md-10">Order# {OrderId}</span>
                        </div>
                    </div>
                    <div className='col-11 offset-1 col-md-10 offset-md-2 quote mt-2'>{OrderName}</div>
                </div>
                <div className="col-12 col-md-7 mb-5 mb-md-0 order-first order-md-last">
                    <div className="col-12 px-0">
                        <BarOrderStatus status={OrderStatus} warningText={warningText} view={VIEW_BAR_STATUS.order} title={ORDER_STATUSES_TITLE} statusBar={ORDER_STATUSES_BAR} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-5 mb-3">
                    <div className="col-11 offset-1 col-md-10 offset-md-2 order-details mt-4 mb-3">
                        <div className="row">
                            <p className="col-12">PO Reference: {POReference}</p>
                            <p className="col-12">Order Date: {moment(Number(DateCreated)).format("ddd, D MMM")}</p>
                        </div>
                    </div>
                    <div className="col-12 delivery-method my-3">
                        <div className="row">
                            <div className='col-1 col-md-2 px-0 d-flex justify-content-center justify-content-md-end px-md-3'>
                                <div className="row">
                                    {deliveryMethod === DELIVERY_METHOD_PLANS.express ? <div className="icon-express"></div> : null}
                                </div>
                            </div>
                            <span className="col-11 col-md-10">{deliveryMethod}</span>
                        </div>
                    </div>
                    <div className="col-11 offset-1 col-md-10 offset-md-2 delivery-date">
                        <p className="estimated">Estimated delivery:</p>
                        <p className="date">{estimateDelivery ? moment(estimateDelivery).format("ddd D MMM") : null}</p>
                    </div>

                    <Query query={GET_COORDS} variables={{orderId: OrderId}}>
                        {
                            ({ error, loading, data }) => {
                                if(loading) {
                                    return (<Spinner size='small' />)
                                }
                                return data && data.getCoords ? (<div className="col-11 offset-1 col-md-10 offset-md-2 details my-4">
                                <MapBox lat={Number(data.getCoords.latitude)} lng={Number(data.getCoords.longitude)}/>
                                </div>) : ''
                            }
                        }
                    </Query>
                    {OrderTotal ? (
                        <div className="col-11 offset-1 col-md-10 offset-md-2 details my-4">
                            <div className="row">
                                <p className="col-12">Total: <span className="order-price">£{Math.round(OrderTotal)+DeliveryCost}</span></p>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="col-11 offset-1 mb-3 col-md-7 offset-md-0 mt-md-3 px-xl-0">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-3 mt-md-3">
                            <p className="col-12 px-0 title"> Jobs </p>
                        </div>
                        <div className="table col-12 col-md-12">
                            <div className="col-12 thead">
                                <div className="row mb-3">
                                    <div className="col-2 col-md-2 px-0">Ref #</div>
                                    <div className="col-6 col-md-3">Name</div>
                                    <div className="col-2 d-none d-md-inline-block">Qty</div>
                                    <div className="col-4 col-md-4 d-none d-md-inline-block">
                                        <div className="col-10 px-2 float-right">Status</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {renderJobList(jobs, props)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
        </ApolloProvider>
    );
}

export default OrderDetail;