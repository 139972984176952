import React from 'react';
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';
import moment from 'moment';
import Spinner from '../Spinner/Spinner';
import { DELIVERY_METHOD_PLANS, ORDER_QUOTE } from '../../constants/constants';
import './style.scss';

const client = new ApolloClient(apolloSecureConfig);

const arrowAsc = require("../../assets/img/quotes/arrow-asc.svg");
const arrowDesc = require("../../assets/img/quotes/arrow-desc.svg");

const GET_QUOTE = gql`
    query getQuote($orderId: Int!) {
        getQuote(orderId: $orderId) {
            OrderId
            QuoteName
            ClientReference
            ProductId
            Quantity
            FinishedSizeWidth
            FinishedSizeHeight
            Pages
            FlatSizeWidth
            FlatSizeHeight
            PrintSpecType
            PrintSpecTypeCover
            PaperId
            CoverPaperId
            LaminationFrontId
            LaminationBackId
            LaminationCoverId
            LaminationCoverFrontId
            LaminationCoverBackId
            DeliveryCost
       }
     }
`;


class SavedQuoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.editQuoteSubscription = {};
    }
    componentWillUnmount() {
        if (Object.keys(this.editQuoteSubscription).length) {
            this.editQuoteSubscription.unsubscribe();
        }
    }
    renderQuote = (quotes) => {
        if (quotes.length) {
            return quotes.map((quote) => {
                const totalPriceUnit = Math.round(quote.OrderTotal) + quote.DeliveryCost;
                return (
                    <div className="row mb-3 job" key={quote.OrderId} onClick={() => this.props.history.push('/editquote/' + quote.OrderId)}>
                        <div className="col-3 offset-1 col-md-3 offset-md-0 d-none d-md-inline-block">
                            <div className="row">
                                <div className="col-3 d-flex justify-content-end align-items-center">
                                    {quote.DeliveryPlan === DELIVERY_METHOD_PLANS.express ?
                                        <div className="icon-express"></div>
                                        : null} </div>
                                <div className="col-6"> {quote.OrderId}</div>
                            </div>
                        </div>
                        <div className="col-4 d-inline-block d-md-none">
                            <div className="row">
                                <div className="col-6 d-flex justify-content-end align-items-center">
                                    {quote.DeliveryPlan === DELIVERY_METHOD_PLANS.express ?
                                        <div className="icon-express"></div>
                                        : null} </div>
                                <div className="col-6 px-0"> {quote.OrderId}</div>
                            </div>
                        </div>
                        <div className="col-3 d-none d-md-inline-block">
                            <div className="col-12 px-0">{moment(Number(quote.DateCreated)).format("ddd, D MMM YYYY")}</div>
                            <div className="col-12 px-0 details my-2">{moment(Number(quote.DateCreated)).format("h:mma")}</div>
                        </div>
                        <div className="col-6 col-md-3">{quote.OrderName ? quote.OrderName : ''}</div>
                        <div className="col-1 d-none d-md-inline-block">{quote.Quantity ? quote.Quantity : null}</div>
                        <div className="col-1 d-none d-md-inline-block">£{totalPriceUnit}</div>
                        <div className="col-1">
                            <button type="button" className="button-edit">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                                </svg>
                            </button>
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <tr key='emptyQuotes'>
                    <td>You don't have any quotes</td>
                </tr>
            )
        }
    }

    editQuote = (orderId) => {
        this.editQuoteSubscription = client.watchQuery({
            query: GET_QUOTE,
            variables: { orderId },
            fetchPolicy: 'network-only'
        }).subscribe({
            next: ({ data }) => {
                if (data) {
                    const newQuote = { ...data.getQuote };
                    this.props.changeEditQuote(newQuote);
                }
            },
            error: (e) => console.error(e)
        })
    }

    removeQuote = orderId => {
        this.props.removeQuote(orderId);
    }

    orderQuote = () => {
        this.props.orderQuote();
    }

    renderPagination = () => {
        const { quotes, currentPage } = this.props;

        const renderPage = () => {
            if (quotes.totalPages < 6) {
                return new Array(quotes.totalPages).fill(1).map((elem, index) => {
                    return (
                        <li className={`page-item ${currentPage === index ? 'active' : ''}`} key={index}>
                            <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.props.changePage(index)}>{index + 1}</a>
                        </li>
                    )
                })
            }
            else {
                return new Array(quotes.totalPages).fill(1).map((elem, index) => {
                    if (index === 0 || (index === 1 && currentPage < 2) || index === currentPage  || (currentPage === quotes.totalPages-1 && index === quotes.totalPages - 2) || index === quotes.totalPages - 1) {
                        return (
                            <li className={`page-item ${currentPage === index ? 'active' : ''}`} key={index}>
                                <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.props.changePage(index)}>{index + 1}</a>
                            </li>
                        )
                    }
                    if (index === 1 || (currentPage === quotes.totalPages-1 && index === quotes.totalPages - 3)) {
                        return (
                            <li className={`page-item ${currentPage === index ? 'active' : ''}`} key={index}>
                                <a className="page-link d-flex justify-content-center align-items-center" onClick={() => onChangePrevious()}>...</a>
                            </li>
                        )
                    }
                    if (index === quotes.totalPages - 2) {
                        return (
                            <li className={`page-item ${currentPage === index ? 'active' : ''}`} key={index}>
                                <a className="page-link d-flex justify-content-center align-items-center" onClick={() => onChangeNext()}>...</a>
                            </li>
                        )
                    }
                })
            }
        }

        const onChangeNext = () => {
            if (currentPage < quotes.totalPages - 1) {
                this.props.changePage(currentPage + 1);
            }
        }

        const onChangePrevious = () => {
            if (currentPage > 0) {
                this.props.changePage(currentPage - 1);
            }
        }

        return (
            <ul className="pagination" >
                <li className="page-item">
                    <a className="page-link side" onClick={() => onChangePrevious()}>
                        <button type="button" className="button-edit">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.88436 20.2539C15.2984 20.2539 19.7031 15.861 19.7031 10.4614C19.7031 5.06189 15.2984 0.668993 9.88436 0.668992C4.4703 0.668992 0.0656008 5.06189 0.0656003 10.4614C0.0655998 15.861 4.4703 20.2539 9.88436 20.2539ZM9.88436 5.56522L9.88436 9.4822L14.7937 9.4822L14.7937 11.4407L9.88436 11.4407L9.88436 15.3577L4.97498 10.4614L9.88436 5.56522Z" fill="var(--faded)" />
                            </svg>
                        </button>
                    </a>
                </li>
                {renderPage()}
                <li className="page-item">
                    <a className="page-link side" onClick={() => onChangeNext()}>
                        <button type="button" className="button-edit">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                            </svg>
                        </button>
                    </a>
                </li>
            </ul>
        )
    }

    render() {
        const { quotes, filter } = this.props;
        return (
            <section className="saved-quote col-12 mt-5">
                <div className="row">
                    {Object.keys(quotes).length ?
                        <div className="table-responsive col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <div className="table">
                                    <div className="thead row mb-4">
                                        <div onClick={() => this.orderQuote()} className="col-3 d-none d-md-inline-block">
                                            <div className="offset-3 px-2">Quote ID <img src={filter.OrderId === ORDER_QUOTE.asc ? arrowAsc : arrowDesc} alt="icon" /></div>
                                        </div>
                                        <div onClick={() => this.orderQuote()} className="col-4 d-inline-block d-md-none">
                                            <div className="col-6 px-0 float-right offset-md-3 px-md-2">ID <img src={filter.OrderId === ORDER_QUOTE.asc ? arrowAsc : arrowDesc} alt="icon" /></div>
                                        </div>
                                        <div className="col-3 d-none d-md-inline-block">Date Created</div>
                                        <div className="col-6 col-md-3">Name</div>
                                        <div className="col-1 d-none d-md-inline-block">QTY</div>
                                        <div className="col-1 d-none d-md-inline-block">Price</div>
                                    </div>
                                <div className="col-12 px-0">
                                    {this.renderQuote(quotes.content)}
                                </div>
                            </div>
                        </div>
                        : <Spinner size='small' />}
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        {this.renderPagination()}
                    </div>
                </div>
            </section >
        );
    }
}

export default withRouter(SavedQuoteForm);