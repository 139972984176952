export const validateLogin = values => {
    let errors = {};
    if (!values.email) {
        errors.email = 'Please set a valid email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Please set a password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i.test(values.password)) {
        errors.password = 'Invalid password';
    }
    return errors;
};

export const validateCreateQuote = values => {
    let errors = {};
    if (!values.name) {
        errors.name = 'Please set a quote name';
    }
    if (!values.reference) {
        errors.reference = 'Please set a reference';
    }
    return errors;
};

export const validateProfileUser = values => {
    let errors = {};
    if (!values.FirstName) {
        errors.FirstName = 'Please set a first name';
    }
    if (!values.LastName) {
        errors.LastName = 'Please set a last name';
    }
    if (!values.Email) {
        errors.Email = 'Please set a email';
    }
    if (!values.Title) {
        errors.Title = 'Please set a title';
    }
    return errors;
};

export const validateForgotPassword = values => {
    let errors = {}
    if (!values.email) {
        errors.email = 'Please set an email address';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
}

export const validateResetPassword = values => {
    let errors = {}
    if (!values.newPassword) {
        errors.newPassword = 'Please set a password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i.test(values.newPassword)) {
        errors.newPassword = 'Invalid password';
    }
    return errors;
}

export const validateRegistration = values => {
    let errors = {};
    if (!values.FirstName) {
        errors.FirstName = 'Please set your first name';
    }
    if (!values.LastName) {
        errors.LastName = 'Please set your last name';
    }
    if (!values.Email) {
        errors.Email = 'Please set an email address';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
        errors.Email = 'Invalid email address';
    }
    if (!values.PasswordHash) {
        errors.PasswordHash = 'Please set a password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i.test(values.PasswordHash)) {
        errors.PasswordHash = 'Invalid password';
    }
    if (!values.ConfirmPassword) {
        errors.ConfirmPassword = 'Please set a confirmation password';
    } else if (values.ConfirmPassword !== values.PasswordHash) {
        errors.ConfirmPassword = "Passwords don't match";
    }

    return errors;
};

export const validateUpdatePassword = values => {
    let errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = 'Please set your current password';
    }
    if (!values.newPassword) {
        errors.newPassword = 'Please set a new password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i.test(values.newPassword)) {
        errors.newPassword = 'Invalid password';
    } else if (values.currentPassword === values.newPassword) {
        errors.newPassword = 'Please set a different password';
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = 'Please set a confirmation password';
    } else if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = "Passwords don't match";
    }

    return errors;
};