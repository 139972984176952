import React from 'react';
import Quotes from './Quotes';
import { QUOTES_TABS } from '../../constants/constants';

class QuotesContainer extends React.Component {
    constructor(props) {
        super(props)
        const currentPathName = props.location.pathname.replace("/", "");
        this.state = { activeTabID: currentPathName === QUOTES_TABS.new ? QUOTES_TABS.new : QUOTES_TABS.saved }
    }
    changeTab = (id) => {
        this.setState({ activeTabID: id });
        this.props.history.push("/" + id);
    }
    render() {
        const { activeTabID } = this.state;
        return (
            <Quotes activeTab={activeTabID} changeTab={this.changeTab} />
        );
    }
}
export default QuotesContainer;