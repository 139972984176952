import React from 'react';
import './style.scss';

const BasicHeader = () => {

    return (
        <section className="header col-12">
                <div className="row" >
                    <div className="col-4 col-md-2 col-lg-2 order-second order-md-first d-flex justify-content-center mt-3 mb-4">
                        <a href="/" className="logo-header">
                            <img src={require('../../assets/img/printhub_logo.svg')} alt="logo" className="img-fluid" />
                        </a>
                    </div>
                </div>
        </section>
    );
}

export default BasicHeader;
