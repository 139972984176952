import React from 'react';
import Orders from './Orders';

class OrdersContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { activeTabID: "orders" }
    }

    changeTab = (id) => {
        this.setState({ activeTabID: id });
    }

    render() {
        const { activeTabID } = this.state;
        return (
            <Orders activeTab={activeTabID} changeTab={this.changeTab} />
        );
    }
}
export default OrdersContainer;