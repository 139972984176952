import React from 'react';
import Spinner from '../Spinner/Spinner';
import './style.scss';
const LoaderApp = () => (
    <div className={'loaderApp'}>
        <div  className={'spinContainer'}>
        <Spinner size='bigger' />
    </div>
    </div>
);
export default LoaderApp;