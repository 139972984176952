import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import './style.scss';

const OpenQuotesAreaChart = (props) => {
    const totalHeight = 122;
    const conversionHeight = props.conversionRate !== 0 ? ((props.conversionRate * totalHeight) / 100).toFixed(2) : 0;
    return (
        <div className={'quotes-chart'}>
            <div className="row">
                <div className="col-12 text-small mb-2" onClick={() => props.history.push('/quotes')}>Saved quotes
                    <button type="button" className={`button-edit float-right`}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                        </svg>
                    </button>
                </div>
                <p className="col-9 total-number pb-3" onClick={() => props.history.push('/quotes')}>{props.count}</p>
                <div className="col-9 pb-3">
                    <div className="row">
                        <div className="col-8">
                            <div className="chart-all" onClick={() => props.history.push('/quotes')}>
                                <div className="full-filled" style={{ height: conversionHeight !== 0 ? conversionHeight + 'px' : '0px' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-9 text-chart">{props.conversionRate}% conversion rate</div>
            </div>
        </div>
    )
}
OpenQuotesAreaChart.propTypes = {
    count: PropTypes.number.isRequired,
    conversionRate: PropTypes.number.isRequired
}

export default withRouter(OpenQuotesAreaChart);