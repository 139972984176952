import React from 'react';
import './style.scss';
import { VIEW_BAR_STATUS, TRACKING_STATUSES } from '../../constants/constants';

const BarOrderStatus = (props) => {
    const { title, statusBar, view, orderStatus, SignedBy, status, isComplete } = props;

    const renderBar = (props) => {
        const keyStatus = Object.keys(statusBar[0]);
        const findedIndexOrder = statusBar.findIndex(order => order[keyStatus[0]] === props.status);
        const formatedOrderBar = statusBar.map((order, index) => {
            if (index < findedIndexOrder) {
                return { ...order, line: true, circle: true };
            } else if (index === findedIndexOrder) {
                return { ...order, circle: true, expire: props.warningText ? true : false };
            }
            else if (index === statusBar.length - 1) {
                let activeCompleted = false;
                if (view === VIEW_BAR_STATUS.job && orderStatus) {
                    if (isComplete === 1) {
                        activeCompleted = true;
                    }
                }
                return { ...order, circle: activeCompleted, expire: false };
            }
            else {
                return order;
            }
        })
        const bar = formatedOrderBar.map((order, index) => {
            return (
                <div className="block-bar" key={index}>
                    <div className={`circle ${order.circle ? 'active' : ''} ${order.expire ? 'expire' : ''}`}></div>
                    {index < formatedOrderBar.length - 1 ? (<div className={`line ${order.line ? 'active' : ''}`}></div>) : ''}
                </div>
            )
        });
        const line = formatedOrderBar.map((order, index) => {
            return (
                <div className={`status ${order.circle ? 'active' : ''} ${order.expire ? 'expire' : ''}`} key={index}>
                    {title[order[keyStatus[0]]].title}
                    {view === VIEW_BAR_STATUS.tracking && index === formatedOrderBar.length - 1 && SignedBy && status === TRACKING_STATUSES.Delivered ? <div className="details">Signed by <br /> {SignedBy} </div> : null}
                </div>
            )
        });
        return (
            <div className="row">
                <div className="col-12">
                    <div className="col-12 col-lg-11 mx-0 mx-lg-4 px-0">
                        <div className="row px-2 d-flex justify-content-center justify-content-lg-start">
                            {bar}
                        </div>
                    </div>
                </div>
                <div className="col-12 px-md-3 px-lg-4 px-xl-3 mt-2">
                    <div className="row d-flex justify-content-center justify-content-lg-start">
                        {line}
                    </div>
                </div>
            </div>
        )
    }
    return (
        renderBar(props)
    )
}
export default BarOrderStatus;