import React, { useState } from 'react';
import gql from "graphql-tag";
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ApolloClient } from "apollo-client";
import { Input } from '../FormElements/index.js';

import './style.scss';

const client = new ApolloClient(apolloSecureConfig);

const REJECT_ARTWORK = gql`
  mutation rejectArtwork($input: InputArtwork! ){
    rejectArtwork(input: $input){
        ArtworkId
        Version
        Quantity
        JobId
        ArtworkType
        ArtworkStatus
        ArtworkComments
        DateCreated
        DateProofCreated
        DateProofImposed
        DateProofApproved
        DateProofSentToPrint
        ApprovalRequiredTime
        Expired
    }
  }
`;

const CommentsModal = (props) => {
    const { artworkId } = props;

    const [commentModal, changeCommentModal] = useState({ comment: '' })
    const onChangeComment = (value) => {
        changeCommentModal({ comment: value })
    }
    const rejectArtwork = () => {
        let bodyArtwork = {
            artworkId,
            comment: commentModal.comment
        }
        if (props.approvalRequest) {
            bodyArtwork.clientContactKey = props.clientContactKey;
        }
        client.mutate({
            mutation: REJECT_ARTWORK,
            variables: {
                input: bodyArtwork
            }
        }).then((response) => {
            props.artworkUpdate(response.data.rejectArtwork)
            props.hideModal()
        })
    }

    return (
        <React.Fragment>
            <div className="col-12">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className={`modal fade col-12 ${props.showModal ? 'show' : 'hide'}`} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="container-fluid">
                                {props.showModal ? <div className={`modal-backdrop fade ${props.showModal ? 'show' : 'hide'}`} onClick={() => props.hideModal()}></div> : null}
                                <div className="modal-dialog modal-dialog-centered col-12 col-lg-4" role="document">
                                    <div className="modal-content col-10">
                                        <div className="modal-header mb-5">
                                            <h5 className="modal-title">Comments</h5>
                                        </div>
                                        <div className="modal-footer mt-5">
                                            <div className="col-12 px-0 py-3 d-flex justify-content-center">
                                                <Input
                                                    placeholder="Your comments:"
                                                    type="text"
                                                    name="commentReject"
                                                    labelcols={0}
                                                    fieldClass="text-input"
                                                    labelClass="col-12"
                                                    groupStyle='col-12 px-0'
                                                    value={commentModal.comment}
                                                    changeInput={(value) => onChangeComment(value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="row d-flex justify-content-between">
                                                    <button type="submit" className="btn btn-save col-5" onClick={() => rejectArtwork()}>Submit</button>
                                                    <button type="button" className="btn btn-reject col-5" data-dismiss="modal" onClick={() => props.hideModal()}>Back</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CommentsModal;