import {
  ALL_PRODUCTS,
  ORIENTATION_TYPE,
  COLOURS_THROUGHOUT,
  COLOURS_COVER,
  COLOURS_FRONT,
  COLOURS_BACK,
  BINDING,
  PAPER_TYPE,
  COVER_PAPER_TYPE
} from '../constants/constants';
class OrderService {
  getDefaultQuoteData = (productName, printSpecs) => {
    let quoteData = {
      customSizeWidth: 210,
      customSizeHeight: 297,
      orientation: ORIENTATION_TYPE.portrait,
      coloursThroughout: COLOURS_THROUGHOUT[0].value,
      coloursCover: COLOURS_COVER[0].value,
      coloursCoverFront: COLOURS_COVER[0].value,
      coloursCoverBack: COLOURS_COVER[0].value,
      coloursFront: COLOURS_FRONT[0].value,
      coloursBack: COLOURS_BACK[0].value,
      binding: BINDING[0].value,
      paperType: PAPER_TYPE[2].value,
      coverPaperType: COVER_PAPER_TYPE[0].value,
      Quantity: 100,
      Pages: 4,
      Size: {}
    };
    switch (productName) {
      case ALL_PRODUCTS.leaflet:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        return quoteData;
      case ALL_PRODUCTS.foldedLeaflets:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        return quoteData;
      case ALL_PRODUCTS.postCard:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A6');
        return quoteData;
      case ALL_PRODUCTS.compSlip:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'DL');
        quoteData.Quantity = 1000;
        return quoteData;
      case ALL_PRODUCTS.businessCard:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === '85mm x 55mm');
        quoteData.Quantity = 1;
        quoteData.customSizeWidth = 95;
        quoteData.customSizeHeight = 65;
        return quoteData;
      case ALL_PRODUCTS.fstBrochures:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        quoteData.Quantity = 100;
        quoteData.paperType = PAPER_TYPE[1].value;
        quoteData.Pages = 8;
        quoteData.coverPaperType = COVER_PAPER_TYPE[2].value;
        return quoteData;
      case ALL_PRODUCTS.wiroBound:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        quoteData.Quantity = 1;
        quoteData.Pages = 8;
        quoteData.coverPaperType = COVER_PAPER_TYPE[2].value;
        quoteData.coloursCoverBack = COLOURS_BACK[0].value;
        return quoteData;
      case ALL_PRODUCTS.perfectBoundBrochures:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        quoteData.Quantity = 1;
        quoteData.Pages = 28;
        quoteData.coverPaperType = COVER_PAPER_TYPE[2].value;
        return quoteData;
      case ALL_PRODUCTS.purBoundBrochures:
        quoteData.Size = printSpecs.find(printSpec => printSpec.Name === 'A4');
        quoteData.Quantity = 1;
        quoteData.Pages = 28;
        quoteData.coverPaperType = COVER_PAPER_TYPE[2].value;
        return quoteData;
      default: return quoteData;
    }
  }
}

export let orderService = new OrderService();