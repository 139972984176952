import React from 'react';
import { Row, Col } from 'antd';
import gql from "graphql-tag";
import { ApolloProvider } from 'react-apollo';
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ApolloClient } from "apollo-client";
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { notificationService } from '../../services/NotificationService';
import { validateUpdatePassword } from '../../support/validation';
import './styles.scss';

const client = new ApolloClient(apolloSecureConfig);

const typeInput = {
    text: 'text',
    password: 'password'
};

const UPDATE_PASSWORD = gql`
  mutation changePassword($currentPassword:String!, $newPassword:String!){
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword){
       newPassword
    }
  }
`;
class ProfileSecurity extends React.Component {

    state = {
        activeTypeInput: typeInput.password,
        errorsPassword: []
    }

    onChangeStatus = () => {
        const { activeTypeInput } = this.state;
        this.setState({ activeTypeInput: activeTypeInput === typeInput.text ? typeInput.password : typeInput.text })
    }

    displayErrors = () => {
        const { errorsPassword } = this.state;
        return errorsPassword.map((error, index) => (
            <p className="error" key={index}>{error}</p>
        ))
    }

    createError = (errors) => {
        const errorsMaped = errors && errors.length ? errors.map(error => error.message) : [];
        this.setState({
            errorsPassword: errorsMaped
        });
    }

    onSubmitPassword = (values) => {
        client.mutate({
            mutation: UPDATE_PASSWORD,
            variables: values
        }).then(() => {
            notificationService.showSuccessNotification('Password Changed');
            this.setState({
                errorsPassword: []
            })
        }, error => {
            this.createError(error.graphQLErrors)
        })
    }

    render() {
        const { activeTypeInput, errorsPassword } = this.state;
        return (
            <section className="security-section col-12 mt-5">
                <ApolloProvider client={client}>
                    <Formik enableReinitialize={true}
                        initialValues={{
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        }}
                        validate={validateUpdatePassword}
                        onSubmit={(values) => this.onSubmitPassword(values)}
                    >
                        <Row>
                            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
                                <Form>
                                    <Row className="my-5">
                                        <Col span={20} offset={2}>
                                            <p className="text">Update password</p>
                                        </Col>
                                    </Row>
                                    {errorsPassword && errorsPassword.length ? (
                                        <Row className="mt-5">
                                            <Col span={20} offset={2}>
                                                <div className="form-group text-center">
                                                    {this.displayErrors()}
                                                </div>
                                            </Col>
                                        </Row>
                                    ) : ''}

                                    <Row className="mt-4">
                                        <Col span={21} offset={2}>
                                            <label htmlFor="currentPassword" className="field-label">Current Password</label>
                                            <Field className="form-control px-0" type={activeTypeInput} aria-required="true" name="currentPassword" />
                                            <button className="password-icon float-right" onClick={() => this.onChangeStatus()}>
                                                <svg width="27" height="13" viewBox="0 0 27 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M26.9398 7.56843C25.4087 3.25365 19.6443 0 13.5317 0C7.41906 0 1.65471 3.25395 0.123589 7.56843L0 7.91676L0.123589 8.26479C0.721557 9.95018 1.96056 11.5142 3.70672 12.788C3.90303 12.9312 4.13241 13 4.36024 13C4.69262 13 5.02125 12.8528 5.23597 12.5732C5.59706 12.1022 5.49875 11.4351 5.01564 11.0828C3.7476 10.1577 2.82412 9.06946 2.32509 7.91676C3.19926 5.88791 5.30182 4.18092 7.95554 3.15752C7.41375 4.07627 7.09885 5.13739 7.09885 6.27092C7.09885 9.7284 9.98477 12.5415 13.5317 12.5415C17.0786 12.5415 19.9645 9.7284 19.9645 6.27092C19.9645 5.13648 19.649 4.07444 19.1066 3.15539C21.912 4.23659 24.1007 6.08322 24.875 8.26509C25.0722 8.8203 25.6936 9.11539 26.2644 8.92312C26.8346 8.73086 27.1367 8.12454 26.9398 7.56843ZM17.7799 6.27092C17.7799 8.55441 15.8743 10.412 13.5317 10.412C11.1891 10.412 9.28319 8.55441 9.28319 6.27092C9.28319 3.98743 11.1888 2.12986 13.5314 2.12986C15.874 2.12986 17.7799 3.98743 17.7799 6.27092Z" fill="var(--faded)" />
                                                </svg>
                                            </button>
                                            <ErrorMessage name="currentPassword" component="div" className="error" />
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col span={21} offset={2}>
                                            <label htmlFor="newPassword" className="field-label">New Password</label>
                                            <Field className="form-control px-0" type="password" aria-required="true" name="newPassword" />
                                            <ErrorMessage name="newPassword" component="div" className="error" />
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col span={21} offset={2}>
                                            <label htmlFor="confirmPassword" className="field-label">Confirm Password</label>
                                            <Field className="form-control px-0" type="password" aria-required="true" name="confirmPassword" />
                                            <ErrorMessage name="confirmPassword" component="div" className="error" />
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col span={6} offset={2}>
                                            <div className="form-group text-center">
                                                <button type="submit" className="form-button">Save</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Formik>
                </ApolloProvider>
            </section>
        );
    }
}

export default ProfileSecurity;