import React from 'react';
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';
import EditOrder from './EditOrder';
const queryString = require('query-string');

const client = new ApolloClient(apolloSecureConfig);
const GET_JOB = gql`
    query getJob($jobId: Int!,$clientContactKey: String) {
        getJob(jobId: $jobId,clientContactKey:$clientContactKey ) {
            OrderId
       }
     }
`;

class OrderFromEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: null,
            errors: []
        }
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        client.query({
            query: GET_JOB,
            variables: { jobId: Number(this.props.jobId), clientContactKey: parsed.key }
        }).then((response) => {
            const { getJob } = response.data;
            this.setState({
                orderId: getJob.OrderId
            })
        }, error => {
            if (error.graphQLErrors.length) {
                this.setState({
                    errors: error.graphQLErrors.map(error => error.message)
                });
            }
        })
    }
    displayErrors = () => {
        const { errors } = this.state;
        return errors.map((error, index) => {
            return <p key={index}>{error}</p>
        })
    }
    render() {
        const { orderId, errors } = this.state;
        return orderId ? <EditOrder approvalRequest={this.props.approvalRequest} orderId={orderId} /> : (errors && errors.length ? <div className="col-lg-12 mt-5 section-error">{this.displayErrors()}</div> : null);
    }
}
export default withRouter(OrderFromEmail);