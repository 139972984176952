import React from 'react';
import { Row, Col } from 'antd';
import { Form, Field, ErrorMessage } from 'formik';
import { withRouter } from "react-router-dom";
import './style.scss';

class LoginForm extends React.Component {
  displayErrors = () => {
    return this.props.errorsLogin.map((error,index) => (
    <p className="error" key={index}>{error}</p>
    ))
  }

  render() {
    return (
      <section className="login-section pt-5">
        <Row>
          <Col sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
            <Row type="flex" justify="center">
              <a href="#" className="logo">
                <img src={require('../../assets/img/printhub_logo.svg')} alt="logo" className="img-fluid" />
              </a>
            </Row>

            <Form>
              <Row className="my-5">
                <Col span={20} offset={2}>
                  <p className="hello-text">welcome</p>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <label htmlFor="email" className="field-label">Email address</label>
                  <Field className="form-control px-0" type="email" aria-required="true" name="email" placeholder="you@example.com" />
                  <ErrorMessage name="email" component="div" className="error" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <label htmlFor="password" className="field-label">Password</label>
                  <Field className="form-control px-0" type="password" aria-required="true" name="password" placeholder="Enter your password" />
                  <ErrorMessage name="password" component="div" className="error" />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col span={20} offset={2}>
                  <div className="form-group">
                    <a className="form-forgot" href="/forgotpassword">Forgot password?</a>
                  </div>
                </Col>
              </Row>

              {this.props.errorsLogin && this.props.errorsLogin.length ? (
                <Row className="my-4">
                  <Col span={20} offset={2}>
                    <div className="form-group text-center">
              {this.displayErrors()}
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row className="my-4">
                <Col span={20} offset={2}>
                  <div className="form-group text-center">
                    <button type="submit" className="form-button">Login</button>
                  </div>
                </Col>
              </Row>

            </Form>
          </Col>
        </Row>
      </section>
    );
  }
}


export default withRouter(LoginForm);
