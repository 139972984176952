import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { apolloSecureConfig } from "./support/settings/apollo";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { userService } from "./services/UserService";
import LoaderApp from "./components/loader/LoaderApp";

import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";

const version = "1.2.9";
const client = new ApolloClient(apolloSecureConfig);

const GET_VERSION = gql`
  query getVersion {
    getVersion {
      version
    }
  }
`;

console.info(
  `%c PrintHub Client %c v${version} `,
  "background: #12ADBE; color: #fff",
  "background: #09131D; color: #fff"
);
client
  .query({ query: GET_VERSION })
  .then((result) => {
    console.info(
      `%c PrintHub API %c v${result.data.getVersion.version} `,
      "background: #12ADBE; color: #fff",
      "background: #09131D; color: #fff"
    );
  })
  .catch((error) => {
    console.info(
      `%c PrintHub API %c v ? `,
      "background: #12ADBE; color: #fff",
      "background: #FF004D; color: #fff"
    );
  });

const GET_LOGGED_USER = gql`
  {
    user @client
  }
`;

const GET_USER_ME = gql`
  query me {
    me {
      Title
      FirstName
      LastName
      Mobile
      Telephone
      Email
      AlternateEmail
    }
  }
`;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedApp: false,
      isAuth: false,
    };
  }

  async componentDidMount() {
    const isAuth = userService.isAuth();

    if (isAuth) {
      client
        .query({
          query: GET_USER_ME,
        })
        .then((response) => {
          const { me } = response.data;
          this.setState({
            isAuth: true,
            loadedApp: true,
          });
          client.cache.writeQuery({
            query: GET_LOGGED_USER,
            data: {
              user: me,
            },
          });
        });
    } else {
      this.setState({
        loadedApp: true,
      });
    }
  }

  render() {
    const { isAuth } = this.state;
    const { loadedApp } = this.state;

    return !loadedApp ? (
      <LoaderApp />
    ) : (
      <div>
        {/* {isAuth ? < Header locationPathName={this.props.location.pathname} /> : null} */}
        {this.props.children}
        <ToastContainer />
      </div>
    );
  }
}
export default withRouter(App);
