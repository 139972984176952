import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import { ApolloProvider } from 'react-apollo';
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ApolloClient } from "apollo-client";
import { QUOTES_TABS, ORDER_TABS, GRAVAVATAR_URL } from '../../constants/constants';
import './style.scss';

const client = new ApolloClient(apolloSecureConfig);

const GET_COUNTERS_DATA = gql`
    query getCountersData {
      getCountersData {
            orders
            jobs
            artworks
       }
     }
`;

const GET_PROFILE_DATA = gql`
    query me {
        me {
             Email
             FirstName
             LastName
       }
     }
`;

const Header = (props) => {
    const [showHeaderState, changeHeaderState] = useState({ showHeader: false,hasImage: false });

    const onSaveProfile = (data) => {
        const { me } = data;
        fetch(`${GRAVAVATAR_URL}${me.Email}?fallback=false`).then(response => {
            changeHeaderState({showHeader: showHeaderState.showHeaderState,hasImage:response.status === 200 ? true : false })
        },() => {
            changeHeaderState({showHeader: showHeaderState.showHeaderState,hasImage:false })
        })
    }
    return (
        <ApolloProvider client={client}>
            <section className="header col-12">
                <div className="row">
                    <div className="col-4 col-md-2 col-lg-2 order-second order-md-first d-flex justify-content-center justify-content-md-start mt-2">
                        <a href="/home" className="logo-header">
                            <img src={require('../../assets/img/printhub_logo.svg')} alt="logo" className="img-fluid" />
                        </a>
                    </div>
                    <div className="col-4 col-md-7 col-lg-7 order-first order-md-second">
                        <div className="row">
                            <nav className="navbar navbar-expand-md col-8 offset-3 col-md-8 offset-md-3 col-lg-10 offset-lg-1 d-none d-md-block">
                                <ul className="navbar-nav col-lg-9 offset-lg-3 col-xl-8 offset-xl-4 d-flex justify-content-between align-items-baseline">
                                    <li className={`nav-item ${props.locationPathName === "/home" ? "active" : ""}`}>
                                        <Link to={`/home`} className="nav-link py-4">Home</Link>
                                    </li>
                                    <li className={`nav-item ${props.locationPathName === "/" + QUOTES_TABS.new || props.locationPathName === "/" + QUOTES_TABS.saved || props.locationPathName.includes(QUOTES_TABS.editquote) ? "active" : ""}`}>
                                        <Link to={`/newquote`} className="nav-link py-4">Quotes</Link>
                                    </li>
                                    <li className={`nav-item ${props.locationPathName === "/" + ORDER_TABS.order || props.locationPathName.includes(ORDER_TABS.editorder) ? "active" : ""}`}>
                                        <Link to={`/orders`} className="nav-link py-4">Orders
                                            <p className="badge rounded-circle badge-danger">
                                                <Query fetchPolicy={'network-only'} query={GET_COUNTERS_DATA} >
                                                    {
                                                        ({ error, loading, data }) => {
                                                            return data && data.getCountersData && data.getCountersData.orders > 0 ? data.getCountersData.orders : ''
                                                        }
                                                    }
                                                </Query>
                                            </p>
                                        </Link>
                                    </li>
                                    {/* <li className={`nav-item ${props.locationPathName === "/reports" ? "active" : ""}`}>
                                            <Link to={`/reports`} className="nav-link py-4">Reports</Link>
                                        </li> */}
                                    {/* <li className={`nav-item ${props.locationPathName === "/printspiration" ? "active" : ""}`}>
                                            <Link to={`/printspiration`} className="nav-link py-4">Printspiration</Link>
                                        </li> */}
                                </ul>
                            </nav>
                            {/* Mobile Header Menu */}
                            <nav className="navbar mobile navbar-expand-md mt-1 col-12 d-block d-md-none">
                                <div className="row d-flex align-items-center">
                                    <div className="col-1">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="headerNavigation" onClick={() => changeHeaderState({ showHeader: !showHeaderState.showHeader, hasImage: showHeaderState.hasImage })}>
                                            <span className="navbar-toggler-icon d-flex align-items-center">
                                                {!showHeaderState.showHeader ? <i className="fa fa-bars"></i>
                                                    : <i className="fa fa-times"></i>}
                                            </span>
                                        </button>
                                    </div>

                                    <div id="headerMenu" className={`collapse navbar-collapse col-12 ${showHeaderState.showHeader ? 'show' : 'hide'}`}>
                                        <ul className="navbar-nav col-4 text-center d-flex justify-content-between">
                                            <li className={`nav-item py-4 ${props.locationPathName === "/home" ? "active py-4" : ""}`}>
                                                <Link to={`/home`} className="nav-link">Home</Link>
                                            </li>
                                            <li className={`nav-item py-4 ${props.locationPathName === "/" + QUOTES_TABS.new || props.locationPathName === "/" + QUOTES_TABS.saved ? "active" : ""}`}>
                                                <Link to={`/newquote`} className="nav-link">Quotes</Link>
                                            </li>
                                            <li className={`nav-item py-4 ${props.locationPathName === "/" + ORDER_TABS.order || props.locationPathName.includes(ORDER_TABS.editorder) ? "active" : ""}`}>
                                                <Link to={`/orders`} className="nav-link">Orders
                                                        <p className="badge rounded-circle badge-danger">
                                                        <Query fetchPolicy={'network-only'} query={GET_COUNTERS_DATA} >
                                                            {
                                                                ({ error, loading, data }) => {
                                                                    return data && data.getCountersData && data.getCountersData.orders > 0 ? data.getCountersData.orders : ''
                                                                }
                                                            }
                                                        </Query>
                                                    </p>
                                                </Link>
                                            </li>
                                            {/* <li className={`nav-item py-2 ${props.locationPathName === "/printspiration" ? "active" : ""}`}>
                                                        <Link to={`/printspiration`} className="nav-link">Printspiration</Link>
                                                    </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="col-4 col-md-1 col-lg-1 offset-md-2 offset-lg-2 pt-lg-0 d-flex justify-content-end align-items-top align-items-lg-center">
                        <div className="container-fluid">
                            <div className="row">
                                <div className={`col-12 profile-pic d-flex justify-content-end ${props.locationPathName === "/profile" ? "active" : ""} `}>
                                    <Query fetchPolicy={"cache-and-network"} query={GET_PROFILE_DATA} onCompleted={onSaveProfile} >
                                        {
                                            ({ error, loading, data }) => {
                                                return data.me ? (
                                                    <React.Fragment>
                                                        {showHeaderState.hasImage !== null ? (
                                                            <Link to={`/profile`} className="nav-link py-3 px-0">
                                                                {showHeaderState.hasImage ? (<img src={`${GRAVAVATAR_URL}${data.me.Email}?fallback=false`} className="profile-picture" alt='profile' />) : (<span className="avatar d-flex align-items-center justify-content-center">{data.me.FirstName[0]}{data.me.LastName[0]}</span>)}
                                                            </Link>
                                                        ) : null}
                                                    </React.Fragment>
                                                ) : null
                                            }
                                        }
                                    </Query>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </ApolloProvider>
    );
}

export default Header;
