import React from 'react';
import { withRouter } from "react-router-dom";
import { userService } from '../services/UserService';
import Header from '../components/header/Header';
import './style.scss';

class ErrorPage extends React.Component {

    render() {
        const isAuth = userService.isAuth();

        return (
            <React.Fragment>
                {isAuth ? <Header locationPathName={this.props.location.pathname} /> : null}
                <section className="error-section pt-lg-5 mt-5">
                    <div className="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-9">
                                <div className="container-fluid">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-12 col-lg-4">
                                            <div className="container-fluid">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <p className="col-12 text-center text-oops">oops!</p>
                                                    <p className="col-12 text-center text-page"><span className="mr-2">that page can’t be found</span>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="10" cy="10" r="10" fill="var(--error)" />
                                                            <path d="M8.14938 3H11.8694L11.2494 12.14H8.76938L8.14938 3ZM10.0094 17.16C9.46271 17.16 9.00271 16.9867 8.62938 16.64C8.26938 16.28 8.08938 15.8467 8.08938 15.34C8.08938 14.8333 8.26938 14.4133 8.62938 14.08C8.98938 13.7333 9.44938 13.56 10.0094 13.56C10.5694 13.56 11.0294 13.7333 11.3894 14.08C11.7494 14.4133 11.9294 14.8333 11.9294 15.34C11.9294 15.8467 11.7427 16.28 11.3694 16.64C11.0094 16.9867 10.556 17.16 10.0094 17.16Z" fill="white" />
                                                        </svg>
                                                    </p>
                                                    <p className="col-12 text-center mt-5 text-error">404</p>
                                                    <div className="form-group col-12 d-flex justify-content-center mt-5">
                                                        <div className="col-10">
                                                            {isAuth ?
                                                                <button type="submit" className="form-button" onClick={() => this.props.history.goBack()}>BACK</button>
                                                                : <button type="submit" className="form-button" onClick={() => this.props.history.push('/')}>BACK</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(ErrorPage);