import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col } from 'antd';
import { Form, Field, ErrorMessage } from 'formik';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../constants/constants';
import './style.scss';

class RegisterForm extends React.Component {
  displayErrors = () => {
    return this.props.errorRegister.map((error, index) => (
      <p className="error" key={index}>{error}</p>
    ))
  }
  onCompleteRecaptcha = () => {
    this.props.validateRecaptcha();
  }
  render() {
    const { validRecaptcha, recapthaError } = this.props;
    return (
      <section className="register-section pt-5">
        <Row>
          <Col sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
            <Row type="flex" justify="center">
              <a href="" className="logo">
                <img src={require('../../assets/img/vr-live-logo.svg')} alt="logo" className="img-fluid" />
              </a>
            </Row>
            <Form>
              <Row className="my-5">
                <Col span={20} offset={2}>
                  <p className="hello-text">register</p>
                </Col>
              </Row>

              {this.props.errorRegister && this.props.errorRegister.length ? (
                <Row className="mt-5">
                  <Col span={20} offset={2}>
                    <div className="form-group text-center">
                      {this.displayErrors()}
                    </div>
                  </Col>
                </Row>
              ) : ''}

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <Row>
                    <Col span={11}>
                      <label htmlFor="FirstName" className="field-label">First name</label>
                      <Field disabled={true} className="form-control px-0" type="text" aria-required="true" name="FirstName" placeholder="First name" />
                      <ErrorMessage name="FirstName" component="div" className="error" />
                    </Col>
                    <Col span={11} push={2}>
                      <label htmlFor="LastName" className="field-label">Last name</label>
                      <Field disabled={true} className="form-control px-0" type="text" aria-required="true" name="LastName" placeholder="Last name" />
                      <ErrorMessage name="LastName" component="div" className="error" />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <label htmlFor="Email" className="field-label">Email address</label>
                  <Field disabled={true} className="form-control px-0" type="email" aria-required="true" name="Email" placeholder="you@example.com" />
                  <ErrorMessage name="Email" component="div" className="error" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <label htmlFor="PasswordHash" className="field-label">Password</label>
                  <Field className="form-control px-0" type="password" aria-required="true" name="PasswordHash" placeholder="Enter your password" />
                  <ErrorMessage name="PasswordHash" component="div" className="error" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <label htmlFor="ConfirmPassword" className="field-label">Confirm your password</label>
                  <Field className="form-control px-0" type="password" aria-required="true" name="ConfirmPassword" placeholder="Confirm your password" />
                  <ErrorMessage name="ConfirmPassword" component="div" className="error" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={() => this.onCompleteRecaptcha()}
                  />
                  {!validRecaptcha && recapthaError ? (<p className="error">{recapthaError}</p>) : null}
                </Col>
              </Row>

              <Row className="mt-5">
                <Col span={16} offset={2}>
                  <div className="form-group text-center">
                    <button type="submit" className="form-button">Register</button>
                  </div>
                </Col>
              </Row>

            </Form>
          </Col>
        </Row>
      </section>
    );
  }
}


export default RegisterForm;
