/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import './styles.css';

const showlist = (el, idx, optionClass) => {
  if (el.name) {return (<option className={optionClass} value={el.value} key={idx}>{el.name}</option>)};
  return (<option className={optionClass} value={el} key={idx}>{el}</option>);
};

const RowSelectInput = ({
  field: {...fields},
  fieldClass,
  form: {touched, errors},
  labelcols,
  groupStyle, 
  label,
  labelClass,
  optionClass,
  list,
  defaultValue,
  ...props
}) =>
  <div className={`form-group ${groupStyle}`}>
    <div className="row no-gutters d-flex align-items-center selectdiv">
    {/* {item.icon && <img className="" src={item.icon} />} */}
     {label && <label htmlFor={fields.name} className={`form-label ${labelClass}`}>{label}</label>}
        <Field {...props} {...fields} component="select" className={`form-control ${fieldClass}`} >
          <option className={optionClass} value="" >{defaultValue}</option>
          {list.map((item, idx)=>showlist(item, idx, optionClass))}
        </Field>
        <ErrorMessage name={fields.name} component="div" />
      </div>
    </div>

RowSelectInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  list: PropTypes.oneOfType([         // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      desc: PropTypes.string
    }))
  ])
 };

 RowSelectInput.defaultProps = { 
  labelcols: 3,
  groupStyle: '',
  labelClass: '',
  optionClass: '',
 };

export default RowSelectInput;
