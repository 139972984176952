import React from 'react';
import classNames from 'classnames';

class RadioInput extends React.Component {

  radioInputHtml = (item,index, data) => {
    let labelStyle = classNames(data.itemLabelClass, data.itemLabelClassAdditional, { active: item.value === data.value });
    let fieldStyle = classNames(data.itemClass, data.itemClassAdditional);
    const customStyle = data.elementClass ? `${labelStyle} ${data.elementClass.firstClass && index === 0 ? data.elementClass.firstClass : data.elementClass.secondaryClass}`: `${labelStyle}`;
    return (
      <label key={item.value} htmlFor={item.id} className={customStyle}>
        {item.icon && <img className="" alt={item.name} src={data.value !== item.value ? item.icon : item.selectedIcon} />}
        <div>{item.title}</div>
        <input checked={item.value === data.value} type="radio" name={data.name} value={item.value} id={item.id} className={fieldStyle} onChange={(event) => this.props.changedInput(event.target.value)} />
      </label>
    )
  }
  render() {
    const { options, groupClasses, groupLabelClass, groupLabel } = this.props;
    return (
      <div className={classNames(groupClasses)}>
        <h6 className={classNames(groupLabelClass)}>{groupLabel}</h6>
        {options.map((option,index) => { return this.radioInputHtml(option,index, { ...this.props })})}
      </div>
    )
  }
}
export default RadioInput;