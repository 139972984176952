import React from 'react';
import spinner from '../../assets/img/spinner.svg';
import './style.scss';

const Spinner = (props) => (
    <div className='col-12 text-center'>
        {props.size === 'small' ? (<img width={30} height={30} className={'spinner-animated'} src={spinner} alt="spinne"/>)
            : (<img className={'spinner-animated'} src={spinner} alt="spinner"/>)}
    </div>
);

export default Spinner;