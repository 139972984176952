import React from 'react';
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { ApolloProvider, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { apolloConfig } from "../../support/settings/apollo";
import { validateRegistration } from "../../support/validation";
import RegisterForm from "./RegisterForm";
import { Formik } from 'formik';
import { userService } from '../../services/UserService';
import './style.scss';
const queryString = require('query-string');

const client = new ApolloClient(apolloConfig);

const REGISTER = gql`
  mutation createUser($FirstName:String!, $LastName:String!, $Email:String!, $AlternateEmail: String!, $PasswordHash:String!, $Mobile: String!,$ClientContactId: String!){
    createUser(FirstName: $FirstName, LastName: $LastName, Email: $Email, AlternateEmail: $AlternateEmail, PasswordHash: $PasswordHash, Mobile: $Mobile, ClientContactId: $ClientContactId){
        FirstName
        LastName
    }
  }
`;
const GET_REGISTER_USER = gql`
    query getUserByRegistrationToken($userRegisterId: Int!,$token: String!) {
        getUserByRegistrationToken(userRegisterId: $userRegisterId,token: $token) {
            firstName
            lastName
            email
            clientContactId
       }
     }
`;

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorRegister: [],
            errorCheckUser: [],
            recapthaError: '',
            validRecaptcha: false,
            initialValues: {
                FirstName: '',
                LastName: '',
                Email: '',
                AlternateEmail: '',
                PasswordHash: '',
                ConfirmPassword: '',
                Mobile: '',
                ClientContactId: ''
            }
        }
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (Object.keys(parsed).includes('token') && parsed.token.length && this.props.userId && this.props.userId.length) {
            client.query({
                query: GET_REGISTER_USER,
                variables: { userRegisterId: Number(this.props.userId), token: parsed.token }
            }).then((response) => {
                const { getUserByRegistrationToken } = response.data;
                this.setState({
                    initialValues: {
                        ...this.state.initialValues,
                        FirstName: getUserByRegistrationToken.firstName,
                        LastName: getUserByRegistrationToken.lastName,
                        Email: getUserByRegistrationToken.email,
                        ClientContactId: getUserByRegistrationToken.clientContactId
                    }
                })
            }, error => {
                if (error.graphQLErrors.length) {
                    this.setState({ errorCheckUser: error.graphQLErrors.map(error => error.message) });
                }
            })
        } else {
            this.setState({ errorCheckUser: ['Incorrect user id or token'] });
        }
    }
    createError = (errors) => {
        const errorsMaped = errors && errors.length ? errors.map(error => error.message) : [];
        this.setState({
            errorRegister: errorsMaped
        });
    }

    clearError = () => {
        this.setState({
            errorRegister: []
        })
    }
    onValidateRecaptcha = () => {
        this.setState({
            validRecaptcha: true
        })
    }

    displayCheckError = () => {
        const { errorCheckUser } = this.state;
        return errorCheckUser.map((error, index) => {
            return <p key={index}>{error}</p>
        })
    }
    submit = (mutation, data, setSubmitting) => {
        const { validRecaptcha } = this.state;
        if (validRecaptcha) {
            this.setState({
                recapthaError: ''
            })
            setSubmitting(true);
            mutation({
                variables: {
                    FirstName: data.FirstName,
                    LastName: data.LastName,
                    Email: data.Email,
                    AlternateEmail: data.AlternateEmail,
                    PasswordHash: data.PasswordHash,
                    Mobile: data.Mobile,
                    ClientContactId: data.ClientContactId
                }
            })
                .then((response) => {
                    this.clearError()
                    setSubmitting(false);
                    // cookies.set('jwt', response.data.createUser.token, { path: '/' });
                    this.props.history.push('/')
                },
                    (error) => {
                        this.createError(error.graphQLErrors)
                    }
                );
        } else {
            this.setState({
                recapthaError: 'Invalid recaptcha'
            })
        }
    }
    render() {
        const { errorRegister, validRecaptcha, recapthaError, initialValues, errorCheckUser } = this.state;
        const isAuth = userService.isAuth();
        const RegisterView = (
            <ApolloProvider client={client}>
                <Mutation mutation={REGISTER}>
                    {(createUser, { data }) => (
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={validateRegistration}
                            onSubmit={(values, { setSubmitting }) => this.submit(createUser, values, setSubmitting)}
                        >
                            {({ isSubmitting }) => (
                                <RegisterForm validRecaptcha={validRecaptcha} recapthaError={recapthaError} validateRecaptcha={() => this.onValidateRecaptcha()} errorRegister={errorRegister} isSubmitting={isSubmitting} />
                            )}
                        </Formik>

                    )}
                </Mutation>
            </ApolloProvider>
        );
        return !isAuth ? (errorCheckUser && errorCheckUser.length ? (<section className="col-lg-12 offset-1 mt-5 error-check-user"><div className="row">{this.displayCheckError()}</div></section>) : RegisterView) : (<p className="error mx-5">You are already registered</p>)
    }
}

export default withRouter(Register);
