import React, { useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { FILTER_ORDERS_NAME, SHORT_MONTHS } from '../../../constants/constants';
import './style.scss';

const CompletedOrdersWidget = (props) => {
    const fullHeight = 122;
    const allCountMonths = props.jobData.countMonths.map(month => month.count);
    const maxCount = Math.max.apply(null, allCountMonths)
    const countOrderPixel = props.jobData.invoiced !== 0 ? (fullHeight / props.jobData.invoiced).toFixed(2) : 0;
    const [monthState, onChangeMonthState] = useState({ activeIndex: null });

    const onMouseEnterMonth = (index) => {
        onChangeMonthState({ activeIndex: index });
    }
    const onMouseLeaveMonth = (index) => {
        onChangeMonthState({ activeIndex: null });
    }
    const renderMonth = () => {
        return props.jobData.countMonths.map((month, index) => {
            const monthHeight = ((month.count * fullHeight) / maxCount).toFixed(2);
            return (
                <div onMouseEnter={() => onMouseEnterMonth(index)} onMouseLeave={() => onMouseLeaveMonth(index)} key={index} style={{ height: monthHeight !== 0 ? monthHeight + 'px' : '0px' }} className={`bar mr-1 ${moment().format('MMMM') === month.name && monthState.activeIndex === null ? 'currentMonth' : (monthState.activeIndex === index ? 'activeMonth' : '')}`}>
                    {moment().format('MMMM') === month.name && monthState.activeIndex === null ? (<p className={'col-12 text-center'}>{month.count}</p>)
                        : (<p className={'text-center col-12 counter-block pb-1'}>{month.count}</p>)}
                </div>
            )
        })
    }
    return (
        <div className={'jobs-chart'}>
            <div className="row">
                <div className="col-12 text-small mb-2" onClick={() => props.history.push(`/orders?filter=${FILTER_ORDERS_NAME.invoiced}`)}>Completed orders
                <button type="button" className={`button-edit float-right`}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                        </svg>
                    </button>
                </div>
                <p className="col-12 total-number pb-3" onClick={() => props.history.push(`/orders?filter=${FILTER_ORDERS_NAME.invoiced}`)}>{props.jobData.invoiced}</p>
                <div style={{ height: fullHeight + 'px' }} className={'col-12 chart-bar'}>
                    {renderMonth()}
                </div>
                <div className={'col-12 mt-3 chart-month'}>
                    {props.jobData.countMonths.map((month, index) => (<div key={index} className={`text-center mr-1 ${moment().format('MMMM') === month.name && monthState.activeIndex === null ? 'active' : (monthState.activeIndex === index ? 'activeMonthText' : '')}`}>{(moment().format('MMMM') === month.name || monthState.activeIndex === index) ? SHORT_MONTHS[month.name] : SHORT_MONTHS[month.name][0] + SHORT_MONTHS[month.name][1]}</div>))}
                </div>
            </div>
        </div>
    )
}
export default withRouter(CompletedOrdersWidget);