import React from 'react';
import { Row, Col } from 'antd';
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { ApolloProvider, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { apolloConfig } from "../../support/settings/apollo";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateResetPassword } from '../../support/validation';
import './style.scss';
const client = new ApolloClient(apolloConfig);
const queryString = require('query-string');
const RESET_PASSWORD = gql`
    mutation resetPassword($token: String!, $newPassword: String!) {
        resetPassword(token: $token,newPassword: $newPassword) {
            newPassword
       }
     }
`;
const VALIDATE_FORGOT_PASSWORD = gql`
    query validateForgotPasswordToken($token: String!) {
        validateForgotPasswordToken(token: $token) {
            email
       }
     }
`;
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        newPassword: ''
      },
      errorCheckUser: [],
      successNotification: null,
      disabledButton: false,
      hasError: null
    }
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if(parsed.key && parsed.key.length) {
        client.query({
            query: VALIDATE_FORGOT_PASSWORD,
            variables: {
                token: parsed.key
            }
        }).then((response) => {

        },error => {
            this.setState({ errorCheckUser: error.graphQLErrors.map(error => error.message), disabledButton: false,hasError: 'Incorrect key' });
        })
    } else {
        this.setState({
            errorCheckUser: ["Please provide a key for query"],
            hasError: 'Please provide a key for query'
        })
    }
  }
  componentWillUnmount() {
    this.onResetState()
  }
  onResetState() {
    this.setState({
        initialValues: {
            newPassword: ''
          },
          errorCheckUser: [],
          successNotification: null,
          disabledButton: false,
          hasError: null
    })
  }
  displayCheckError = () => {
    const { errorCheckUser } = this.state;
    return errorCheckUser.map((error, index) => {
        return <p className="error" key={index}>{error}</p>
    })
}
  onCompleteRecaptcha = () => {
    this.props.validateRecaptcha();
  }
  clearError = () => {
    this.setState({
      errorCheckUser: [],
      successNotification: 'Password was successfully changed!'
    })
  }

  submit = (mutation,data, setSubmitting) => {
    const parsed = queryString.parse(this.props.location.search);
        this.setState({
            recapthaError: '',
            disabledButton: true
        })
        setSubmitting(true);
        mutation({
            variables: {
                token: parsed.key,
                newPassword: data.newPassword
            }
        })
            .then((response) => {
                this.clearError()
                setSubmitting(false);
            },
                (error) => {
                  this.setState({ errorCheckUser: error.graphQLErrors.map(error => error.message), disabledButton: false });
                }
            );
}

renderResetPassword = () => {
  const { errorCheckUser,successNotification,disabledButton,hasError } = this.state;
  return (
    <section className="forgot-section pt-5">
      {hasError && hasError.length ? (
        <Row>
          <Col sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
          <Row className="mt-5">
              <Col span={20} offset={2}>
                <div className="form-group text-center">
                   <p className="error">{hasError}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
        <Col sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
          <Row type="flex" justify="center">
            <a href="#" className="logo">
              <img src={require('../../assets/img/vr-live-logo.svg')} alt="logo" className="img-fluid" />
            </a>
          </Row>

          {errorCheckUser && errorCheckUser.length ? (
            <Row className="mt-5">
              <Col span={20} offset={2}>
                <div className="form-group text-center">
                  {this.displayCheckError()}
                </div>
              </Col>
            </Row>
          ) : ''}
          {successNotification ? (
              <Row className="mt-5">
              <Col span={20} offset={2}>
                <div className="form-group text-center">
                  <p className="blue-notification">{successNotification}</p>
                </div>
              </Col>
            </Row>
          ) : null}
            <Form>
              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <Field className="form-control px-0" type="password" aria-required="true" name="newPassword" placeholder="New password" />
                  <ErrorMessage className="error" name="newPassword" component="div" />
                </Col>
              </Row>

              <Row className="my-4">
                <Col span={20} offset={2}>
                  <div className="form-group text-center">
                    <button disabled={disabledButton} type="submit" className="form-button">Reset password</button>
                  </div>
                </Col>
              </Row>
            </Form>
        </Col>
      </Row>
      )}
    </section>
  );
}

  render() {
    const { initialValues } = this.state;
    return (
      <ApolloProvider client={client}>
      <Mutation mutation={RESET_PASSWORD}>
          {(resetPassword) => (
          <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validateResetPassword}
          onSubmit={(values, { setSubmitting }) => this.submit(resetPassword,values, setSubmitting)}
        >
                  {({ isSubmitting }) => this.renderResetPassword()}
              </Formik>
          )}
      </Mutation>
  </ApolloProvider>
    )
  }
}


export default withRouter(ResetPassword);
