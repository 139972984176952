import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "../App";
import { userService } from '../services/UserService';

import Login from "./account/Login";
import Register from "./account/Register";
import ForgotPassword from "./account/ForgotPassword";
import ResetPassword from './account/ResetPassword';
import ErrorPage from "./ErrorPage";
import Home from "./Home";

import ProfileContainer from "../components/profile/ProfileContainer";

import QuotesContainer from "../components/quotes/QuotesContainer";
import EditQuoteContainer from "../components/quotes/EditQuoteContainer";

import OrdersContainer from "../components/orders/OrdersContainer";
import EditOrder from "../components/orders/EditOrder";
import EditQuote from "../components/quotes/EditQuote";
import OrderFromEmail from '../components/orders/OrderFromEmail';



const Navigation = (props) => {
  let routes = [];
  if (userService.isAuth()) {
    routes = [
      <Route key="/" exact path="/" component={Home} />,
      <Route key="/profile" exact path="/profile" component={ProfileContainer} />,
      <Route key="/home" exact path="/home" component={Home} />,
      <Route key="/quotes" exact path="/newquote" component={QuotesContainer} />,
      <Route key="/quotes" exact path="/newquote/:quoteId" component={({ match }) => <EditQuoteContainer orderId={match.params.quoteId} />} />,
      <Route key="/quotes" exact path="/quotes" component={QuotesContainer} />,
      <Route key="/editquote" exact path="/editquote/:quoteId" component={({ match }) => <EditQuote orderId={match.params.quoteId} />} />,
      <Route key="/orders" exact path="/orders" component={OrdersContainer} />,
      <Route key="/Job/ViewApprovalRequest/:jobId" exact path="/Job/ViewApprovalRequest/:jobId" component={({ match }) => <OrderFromEmail approvalRequest={true} jobId={match.params.jobId} />} />,
      <Route key="/editorder" exact path="/editorder/:orderId" component={({ match }) => <EditOrder approvalRequest={false} orderId={match.params.orderId} />} />,
      <Route key="*" exact path="*" component={() => <ErrorPage isAuth={true} />} />,
    ];
  }
  else {
    routes = [
      <Route key="/" exact path="/" component={Login} />,
      <Route key="/login" exact path="/login" component={Login} />,
      <Route key="/register" exact path="/Account/Register/:userId" component={({ match }) => <Register userId={match.params.userId} />} />,
      <Route key="/forgotpassword" exact path="/forgotpassword" component={ForgotPassword} />,
      <Route key="/resetPassword" exact path="/resetPassword" component={ResetPassword} />,
      <Route key="/Job/ViewApprovalRequest/:jobId" exact path="/Job/ViewApprovalRequest/:jobId" component={({ match }) => <OrderFromEmail approvalRequest={true} jobId={match.params.jobId} />} />,
      <Route key="*" exact path="*" component={() => <ErrorPage isAuth={false} />} />
    ];
  }
  return (
    <Router>
      <App>
        <Switch>
          {routes}
        </Switch>
      </App>
    </Router>
  )
};

export default Navigation;
