import React from 'react';
import gql from "graphql-tag";
import { ApolloProvider, Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { apolloConfig } from "../../support/settings/apollo";
import { validateLogin } from "../../support/validation";
import LoginForm from "./LoginForm";
import { Formik } from 'formik';
import Home from '../../pages/Home';
import { userService } from '../../services/UserService';
import './style.scss';

const client = new ApolloClient(apolloConfig);

const LOGIN = gql`
  mutation loginUser($email:String!, $password:String!){
    loginUser(email:$email, password:$password){
        token
    }
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorsLogin: []
    }
  }

  createLoginErrors = (errors) => {
    const errorsMaped = errors && errors.length ? errors.map(error => error.message) : [];
    this.setState({
      errorsLogin: errorsMaped
    });
  }

  clearLoginErrors = () => {
    this.setState({
      errorsLogin: []
    });
  }

  saveTokenToLocalStorage = (token) => {
    userService.saveToLocalStorage(token);
  }

  setUserData = (client, res) => {
    this.clearLoginErrors();
    // cookies.set('jwt', res.data.loginUser.token, { path: '/' });
    this.saveTokenToLocalStorage(res.data.loginUser.token);
    client.cache.writeData({
      data: {
        authStatus: {
          __typename: 'authStatus',
          status: 'loggedIn',
        },
      }
    })
  }

  render() {
    const { errorsLogin } = this.state;
    const isAuth = userService.isAuth();
    const LoginView = (
      <ApolloProvider client={client}>
          <Mutation mutation={LOGIN}>
              {(loginUser) => (
                  <Formik
                      initialValues={{ email: '', password: '' }}
                      validate={validateLogin}
                      onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(false);
                          loginUser({ variables: { email: values.email, password: values.password } })
                              .then((res) => {
                                  this.setUserData(client, res);
                                  window.location.assign('/home')
                              }
                              ).catch((error) => {
                                  this.createLoginErrors(error.graphQLErrors);
                              });
                      }}
                  >
                      {({ isSubmitting }) => (
                          <LoginForm errorsLogin={errorsLogin} isSubmitting={isSubmitting} />
                      )}
                  </Formik>
              )}
          </Mutation>
      </ApolloProvider>
  );
      return !isAuth ? LoginView : <Home />
  }

}

export default withRouter(Login);
