import React from "react";

class SelectInput extends React.Component {
  showlist = (el, idx, optionClass, itemValue) => {
    if (el.name) {
        if(el.disabled){
            return (
                <option disabled className={optionClass} value={el.value} key={idx}>
                  {el.name}
                </option>
              );
        } else {
        return (
            <option className={optionClass} value={el.value} key={idx}>
            {el.name}
            </option>
        );
        }
    }
    return (
      <option className={optionClass} value={el} key={idx}>
        {el}
      </option>
    );
  };
  render() {
    const {
      groupStyle,
      label,
      labelClass,
      fieldClass,
      options,
      optionClass,
      name,
      selectedOption,
      active,
      itemValue,
    } = this.props;
    return (
      <div className={`form-group ${groupStyle}`}>
        <div
          className={`row no-gutters d-flex align-items-center selectdiv ${
            active ? "active" : ""
          }`}
        >
          {label && (
            <label htmlFor={label} className={`form-label ${labelClass}`}>
              {label}
            </label>
          )}
          <select
            value={itemValue ? itemValue : ""}
            name={name}
            className={`form-control ${fieldClass} `}
            onChange={(event) => this.props.changeSelect(event.target.value)}
          >
            {selectedOption && selectedOption.value ? (
              <option
                className={optionClass}
                value={selectedOption.value}
              >
                {selectedOption.name}
              </option>
            ) : null}
            {options
              .filter((op) => op.value !== selectedOption.value)
              .map((item, idx) =>
                this.showlist(item, idx, optionClass, itemValue)
              )}
          </select>
        </div>
      </div>
    );
  }
}
export default SelectInput;
